%svg-common {
	background: url("../images/sprites/main.svg") no-repeat;
}

.svg-image-arrow {
	@extend %svg-common;
	background-position: 45.39007092198582% 60.07974683544304%;
}

.svg-image-arrow-dims {
	width: 49px;
	height: 10px;
}

.svg-image-arrow--big {
	@extend %svg-common;
	background-position: 0 60.07974683544304%;
}

.svg-image-arrow--big-dims {
	width: 64px;
	height: 10px;
}

.svg-image-arrow--black {
	@extend %svg-common;
	background-position: 69.32515337423312% 58.5962962962963%;
}

.svg-image-arrow--black-dims {
	width: 27px;
	height: 6px;
}

.svg-image-close {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-image-close-dims {
	width: 94.926px;
	height: 94.926px;
}

.svg-image-close--black {
	@extend %svg-common;
	background-position: 99.84433178366326% 0;
}

.svg-image-close--black-dims {
	width: 94.926px;
	height: 94.926px;
}

.svg-image-facebook {
	@extend %svg-common;
	background-position: 0 99.47638888888889%;
}

.svg-image-facebook-dims {
	width: 14px;
	height: 24px;
}

.svg-image-instagram {
	@extend %svg-common;
	background-position: 17.08433734939759% 72.86527777777778%;
}

.svg-image-instagram-dims {
	width: 24px;
	height: 24px;
}

.svg-image-linkedin {
	@extend %svg-common;
	background-position: 51.63428571428572% 68.57908496732027%;
}

.svg-image-linkedin-dims {
	width: 15px;
	height: 15px;
}

.svg-image-photography-gallery-next {
	@extend %svg-common;
	background-position: 43.38636363636363% 68.57908496732027%;
}

.svg-image-photography-gallery-next-dims {
	width: 14px;
	height: 15px;
}

.svg-image-play {
	@extend %svg-common;
	background-position: 0 80.91147439851943%;
}

.svg-image-play-dims {
	width: 28.36px;
	height: 38.32px;
}

.svg-image-twitter {
	@extend %svg-common;
	background-position: 31.542168674698797% 70.89594594594595%;
}

.svg-image-twitter-dims {
	width: 24px;
	height: 20px;
}

