.header{
	padding:20px 0;
	position: fixed;
	top:0;
	left:0;
	width: 100%;
	z-index: 2;
	color:#fff;
	opacity: 0;
    transition: transform 1s cubic-bezier(.3,0,0,1) ,opacity .5s ease-in-out 2s;
    transform:translateY(0);
	&__wrap{
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
	&__logo{
		display: none;
		position: absolute;
	    top: 15px;
    	left: 50px;
	    width: 66px;
	    img{
	    	display: block;
	    	width:100%;
	    }
	}
	&__menu{
		text-transform: uppercase;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 1.2px;
		display: flex;
		&__unit{
			margin-right: 40px;
			&:last-child{
				margin-right: 0;
			}
			a{
				position: relative;
				display: inline-block;
				&:before,&:after{
					position: absolute;
					content:"";
					width:0;
					bottom:0;
					left:0;
					height:1px;
					background-color: #fff;
				    transition: .3s cubic-bezier(.5,0,.2,1);
				}
				&:before{
					opacity: .4;
				}
				&:hover{
					&:before,&:after{
						width:100%;
					}
					&:after{
						transition-delay: .15s;
					};
				};
			}
		}
	}
	&--shown{
		opacity: 1;
	}
	&--translated{
		transform:translateY(-100%);
	}
	&--logo{
		transition: transform 1s cubic-bezier(.3,0,0,1) ,opacity .5s ease-in-out;
		.header__logo{
			display: block;
		}
	}
	&--work{
		transition: transform 1s cubic-bezier(.3,0,0,1) ,opacity .5s ease-in-out .2s;
	}
}
@media(max-width:1600px){
	.header{
		&__menu{
			font-size: 12px;
			line-height: 18px;
			&__unit{
				margin-right: 30px;
			}
		}
		&__logo{
			width:50px;
	        top: 16px;
    		left: 37px;
		}
	}
}
@media(max-width:1023px){
	.header{
		&__wrap{
			display: block;
		}
		&__menu{

			margin-bottom: 15px;
		}
		&__logo{
			top:30px;
			left:20px;
		}
	}
}
@media(max-width:760px){
	.header{
	    padding: 36px 0;
		&__wrap{
			display: none;
		}
		&__logo{

			width: 41px;
			top: 30px;
    		left: 10px;
		}
		
	}
}