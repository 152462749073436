@keyframes photography-gallery{
	0%{
		transform:scaleX(0);
	}
	49%{
		transform-origin: left center;	
	}
	50%{
		transform:scaleX(1);
		transform-origin: right center;
	}
	100%{
		transform:scaleX(0);
		transform-origin: right center;
	}
}
@keyframes hero-scale{
	0%{
		transform:scale(1)rotate(0deg);
	}

	50%{
		transform:scale(1.2)rotate(-5deg);
	}
	100%{
		transform:scale(1);
	}
}
.photography{
	&__title{
		pointer-events: all;
		font-size: 85px;
		line-height: 90px;
		letter-spacing: -3.03571px;
		font-weight: 300;
	}
	&__hero{
		position: relative;
		height:100vh;
		min-height: 800px;
		max-height: 1100px;
		&__slider{
			height:100%;
			&__unit{
				max-height: 1100px;
				display: flex!important;
				align-items: center;
				justify-content: center;
				position: relative;
				height:100vh;
				min-height: 800px;
				padding-bottom: 71px;
				&__image{
					
					position: absolute;
					top:0%;
					left:0;
					transform-origin: center;
					width:100%;
					height:100%;
					background-size: cover;
					background-position:top center;
					will-change: opacity,transform;
					opacity: 0;
					transform:scale(1.1);
					transition:opacity .7s cubic-bezier(0.55, 0.085, 0.68, 0.53), transform .7s cubic-bezier(0.55, 0.085, 0.68, 0.53);
					&__wrap{
						will-change:transform;
						transition: transform .7s cubic-bezier(0.55, 0.085, 0.68, 0.53);
						position: absolute;
						top:0;
						left:0;
						width: 100%;
						height:100%;
						transform:scale(1);
						transform-origin: center center;
						
					}
					
				}
				&__title{
					text-align: center;
					text-transform: uppercase;
					font-weight: 300;
					position: relative;
					font-size: 64px;
					line-height: 60px;
					letter-spacing: 6px;
					will-change: opacity,transform;
					transition:opacity .7s cubic-bezier(0.55, 0.085, 0.68, 0.53), transform .7s cubic-bezier(0.55, 0.085, 0.68, 0.53);
					opacity: 0;
					transform:translateY(-100%);
				}
				&.slick-active{
					.photography__hero__slider__unit{
						&__image{
							opacity: 1;
							transform:scale(1);
							&__wrap{
								animation: hero-scale 35s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
							}
						}
						&__title{
							opacity: 1;
							transform:translateY(0%);
						}
					}
				}
			}
			&__preview{
				&__unit{
					will-change: opacity,transform;
					transition: opacity .5s cubic-bezier(0.55, 0.085, 0.68, 0.53),transform .5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
					opacity: .5;
					background-size: cover;
					background-position:top center;
					position: absolute;
					top:0;
					left:0;
					width: 100%;
					height:100%;
					transform-origin: center;
				}
			}
			&__dots{
				.slick-dots{
					margin:0;
					padding:0;
					display: flex;
					justify-content: center;
				}
				z-index: 1;
				
				position: absolute;
				bottom:0;
				left:0;
				width: 100%;
				
				background: rgba(26, 28, 31, 0.4);
				list-style-type: none;
				li{
					cursor: pointer;
					overflow: hidden;
					position: relative;
					width:188px;
					
					&:before{
						content:"";
						padding-top:37.234042553191489361702127659574%;
						display: block;
					};
					&.slick-active{
						.photography__hero__slider__preview__unit{
							opacity: 1;
						}
					}
					&:hover{
						.photography__hero__slider__preview__unit{
							opacity: 1;
							transform:scale(1.1);
						}
					}
				}
			}
			
		}
		&__controls{
			margin-top:auto;
			margin-left:auto;
			pointer-events: all;
			justify-content: flex-end;
			display: flex;

			&__unit{
				cursor: pointer;
				width: 79px;
				height: 70px;
				border: 2px solid #FFFFFF;
				margin-right: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				&:after{
					z-index: 1;
					position: absolute;
					content:"";
					top:0;
					left:0;
					width:100%;
					height:100%;
					transform-origin: center left;
					background-color: #fff;
					transform:scaleX(0);
				};
				&:last-child{

					margin-right: 0;
				};
				&--prev{
					transform:rotate(180deg);
				}
				&:before{
					position: absolute;
					top:0;
					left:0;
					content:"";
					width: 100%;
					height:100%;
					transform-origin: center;
					will-change: transform;
					transition:transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
					transform:scale(0);
					background-color:#fff;
				};
				svg{
					position: relative;
					z-index: 1;
					transform:translateX(-10%);
					stroke:#fff;
					will-change: stroke;
					transition:stroke .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				}
				&:hover{
					&:before{

						transform:scale(1);
					};
					svg{
						stroke:#000;
					}
				};
			}
		}
		
	}
	&__container{
		pointer-events: none;
		display:flex;
		flex-direction: column;
		justify-content: space-between;
	    padding-top: 90px;
	   	padding-bottom: 161px;
		position: absolute;
		top:0;
		left:0;
		right:0;
		margin:0 auto;
		height:100%;
	}
	&__text{
		padding-top: 119px;
		padding-bottom:116px;
		width:763px;
		margin:0 auto;
		text-align: center;
		p{
			margin:0;
		}
	}
	&__gallery{
		margin-bottom: 133px;
		justify-content: space-between;
		display: flex;
		flex-wrap:wrap;
		&__unit{
			display: flex;
			flex-direction: column;
			max-width: 406px;
			position: relative;
			
			width:calc((100% - 4.687531%)/3);
			min-height: 321px;
			margin-bottom: 30px;
			
			
			
			&:after{
				will-change:transform;
				z-index: 1;
				position: absolute;
				content:"";
				top:0;
				left:0;
				width:100%;
				height:100%;
				transform-origin: center left;
				background-color: #fff;
				transform:scaleX(0);
			};
			&__bg{
				background-size: cover;
				background-position:center;
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height:100%;
			}
			&__title{
				position:absolute;
				bottom:14px;
				left:31px;
				z-index: 1;
				margin-top: auto;
				font-size: 36px;
				line-height: 60px;
				letter-spacing: -1.5px;
				font-weight: 300;
				&__plus{
					display: none;
					position: absolute;
					top:50%;
					transform:translateY(-50%);
					right:-26px;
					width:19px;
					height:19px;
					cursor: pointer;
					&:before,&:after{
						content:"";
						background-color: #fff;
						position: absolute;
						top:50%;
						left:50%;
						transform:translate(-50%,-50%);
						//transform-origin: center center;
						will-change:transform,width,height;
						transition:transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53),width .3s cubic-bezier(0.55, 0.085, 0.68, 0.53),height .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);

					};
					&:before{
						width:100%;
						height:1px;
					}
					&:after{
						width:1px;
						height:100%;
					}
					&:hover{
						&:before{
							width:70%;
						};
						&:after{
							height:70%;
						};
					}
				}

			}
			&__info{
				flex:1;
				z-index: 2;
				visibility: hidden;
				opacity: 0;
				display: flex;
				flex-direction: column;
				
				width: 100%;
				height:100%;
				padding: 26px 31px 23px 31px;
				color:#000;
				will-change: opacity,visibility;
				transition:opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53),visibility .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);

			}
			&__content{
				flex:1;
				width: 100%;
				
				min-height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				opacity: 0;
				will-change: opacity;
				&:before{
					z-index: 1;
					position: absolute;
					content:"";
					bottom:0;
					left:0;
					width:100%;
					height:100%;
					transform-origin: bottom center;
					background-color: #fff;
					transform:scaleY(0);
					will-change:transform;
					transition:transform .5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				}
			}
			&__subtitle{
				letter-spacing: 0;
				font-size: 24px;
				line-height: 45px;
				font-weight: 400;
				margin-bottom: 10px;
			}
			&__text{
				font-size: 16px;
				line-height: 30px;
				margin-bottom: 15px;
			}
			&__link{
				margin-top:auto;
				margin-left: auto;
				padding-right: 21px;
				text-transform: uppercase;
				font-size: 18px;
				line-height: 45px;
				position: relative;
				&:before{
					content:"";
				    top: 14px;
    				right: 4px;
					width: 14px;
					height:15px;
					position: absolute;
					background: url("../images/photography-gallery-next.svg");
				};
			}
			&:hover{
				.photography{
					&__gallery{
						&__unit{
							&__content{
								&:before{
									transition:transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
									transform:scaleY(1);
								}
							}
							&__info{
								opacity: 1;
								visibility: visible;
								transition:opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53) .3s,visibility .3s cubic-bezier(0.55, 0.085, 0.68, 0.53) .3s;
							}
						}
					}
				}
			};
			&--shown{
				.photography{
					&__gallery{
						&__unit{
							&__content{
								opacity: 1;
								transition: opacity .1s linear .4s;
							}
						}
					}
				}
				&:after{
					animation: photography-gallery .8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 1;
					animation-fill-mode: forwards;
				};
			}

		}

	}
	&__packages{
		margin-bottom: 179px;
		&__wrap{
			padding-bottom: 1px;
			display: flex;
			flex-wrap:wrap;
			width: 100%;
		}
		&__unit{
			will-change: opacity,transform;
			transition:transform .5s cubic-bezier(0.445, 0.05, 0.55, 0.95),opacity .5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
			transform:translateY(50%);
			opacity: 0;
			break-inside: avoid;
			border-top:1px solid #fff;
			border-bottom:1px solid #fff;
			margin-bottom: -1px;
			width: 50%;
			padding:46px 80px 42px;
			&__title{
				cursor: pointer;
				font-size: 24px;
				line-height: 45px;
				position: relative;
				&__plus{
					position: absolute;
					top:50%;
					transform:translateY(-50%);
					right:-26px;
					width:39px;
					height:39px;
					cursor: pointer;
					&:before,&:after{
						content:"";
						background-color: #fff;
						position: absolute;
						top:50%;
						left:50%;
						transform:translate(-50%,-50%);
						//transform-origin: center center;
						will-change:transform,width,height;
						transition:transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53),width .3s cubic-bezier(0.55, 0.085, 0.68, 0.53),height .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);

					};
					&:before{
						width:100%;
						height:1px;
					}
					&:after{
						width:1px;
						height:100%;
					}
					
				}
				&:hover{
					.photography__packages__unit__title__plus{
						&:before{
							width:70%;
						};
						&:after{
							height:70%;
						};
					}
				}
			}
			&__body{
				will-change: max-height,height;
				transition:max-height .5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				position: relative;
				max-height: 0px;
				overflow: hidden;
				p{
					margin: 0;
				}
				&__inner{
					padding-top:20px;
				}
			}
			&:nth-child(5),&:nth-child(6){
				margin-bottom: 0;
			}
			&:nth-child(2n-1){
				border-right:1px solid #fff;
			}
			&:nth-child(2n){
				margin-left:-1px;
				border-left:1px solid #fff;
			
			}
			&--expanded{
				.photography__packages__unit{
					&__title{
						&__plus{
							&:after{
								transform:translate(-50%,-50%)rotate(90deg);
							};
						}
					}
					&__body{
						
					}
				}
			}
			&--shown{
				transform:translateX(0%);
				opacity: 1;
			}

		}
		&__title{
			font-weight: 300;
			font-size: 36px;
			line-height: 70px;
			letter-spacing: -2px;
			margin-bottom: 14px;

		}
	}
	&__person{
		background:#1A1919;
		&__image{
		    margin-left: -25%;
		    transform:translateX(-15%);
			width:842px;
			max-width: 842px;
			opacity: 0;
			will-change: opacity,transform;
			transition:transform .5s cubic-bezier(0.55, 0.085, 0.68, 0.53), opacity .5s cubic-bezier(0.55, 0.085, 0.68, 0.53);;
			img{
				display: block;
				width: 100%;
			}
			&--shown{
				opacity: 1;
				transform:translateX(0%);
			}
		}
		&__wrap{
			display: flex;
			position: relative;
		}
		&__info{
			padding-left: 15px;
			padding-top: 78px;
			margin-left: auto;
			width:50%;
		}
		&__name{
			font-size: 36px;
			line-height: 45px;
			margin-bottom: 11px;
		}
		&__company{
			font-size: 24px;
			line-height: 30px;
			color:$grey-2;
			margin-bottom: 47px;
		}
		&__description{
			margin-bottom: 54px;
			p{
				margin-top:0;
				margin-bottom: 30px;
				&:last-child{
					margin-bottom:0;
					padding-right: 30px;
				};

			}
		}
		&__footer{
			justify-content: space-between;
			display: flex;
			align-items: center;
		}
		&__links{
			flex:1;
			max-width: 238px;
			display: flex;
			justify-content: space-between;
			text-transform: uppercase;
			&__unit{
				display: inline-block;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	&__contact{
		padding-bottom: 101px;
		padding-top: 141px;
		&__text{
			text-align: center;
			font-size: 18px;
			line-height: 30px;
			max-width: 842px;
			margin:0 auto;
			margin-bottom: 94px;
		}
		&__form{
			max-width: 100%;
			width:406px;
			margin:0 auto;
		}
	}
}
@media(max-width:1600px){
	.photography{
		&__title{
			font-size: 63.75px;
		    line-height: 67.5px;
		    letter-spacing: -2.25px;
		}
		&__container{
			padding-top: 67.5px;
	   		padding-bottom: 120px;
		}
		&__hero{
			min-height: 600px;
			&__controls{
				&__unit{
					margin-right: 22px;
					width: 60px;
    				height: 52px;
    				svg{
					    transform: translateX(-10%)scale(.75);
    				}
				}
			}
			&__slider{
				&__dots{
					li{
						width: 141px;
					}
				}
				&__unit{
					min-height: 600px;
					padding-bottom: 52.5px;
					&__title{
						font-size: 48px;
						line-height: 	45px;
						letter-spacing: 4.5px;
					}
				}
			}
		}
		&__text{
			font-size: 14px;
		    padding-top: 89px;
		    padding-bottom: 87px;
		    width: 580px;
		}
		&__gallery{
			margin-bottom: 100px;
			&__unit{
				margin-bottom: 23px;
				min-height: 240px;
				&__info{
				    padding: 20px 23px 16px 23px;
				}
				&__title{
					bottom:10px;
					left:23px;
				    font-size: 27px;
				    line-height: 45px;
				    letter-spacing: -1.2px;
				}
				&__subtitle{
					font-size: 18px;
					line-height: 34px;
				}
				&__text{
				    font-size: 12px;
    				line-height: 22px;
				}
				&__link{
				    padding-right: 16px;
				    text-transform: uppercase;
				    font-size: 13.5px;
				    line-height: 34px;
				    &:before{
				    	background-repeat:no-repeat;
				    	top: 11px;
					    right: 4px;
					    width: 10px;
					    height: 10px;
				    };
				}

			}
		}
		&__packages{
		    margin-bottom: 132px;
			&__title{
			    font-size: 27px;
			    line-height: 53px;
			    letter-spacing: -.15px;
			    margin-bottom: 10.5px;
			}
			&__unit{
				padding: 34.5px 60px 31.5px;
				&__title{
					font-size: 18px;
    				line-height: 34px;
    				&__plus{
					    right: -19px;
					    width: 30px;
					    height: 30px;
    				}
				}
				&__body{
					&__inner{
						padding-top: 15px;
					}
				}

			}
		}
		&__person{
			&__image{
				width: 632px;
			}
			&__info{
				padding-left: 12px;
    			padding-top: 59px;
			}
			&__name{
				font-size: 27px;
			    line-height: 34px;
			    margin-bottom: 8px;
			}
			&__company{
				font-size: 18px;
			    line-height: 23px;
			    color: #969696;
			    margin-bottom: 35px;
			}
			&__description{
			    margin-bottom: 39px;
				p{
				    margin-bottom: 20px;
				}
			}
			&__links{
				flex-wrap:wrap;
				margin-left: 20px;
				max-width: 184px;
				&__unit{
					
				}
			}
		}
		&__contact{
		    padding-top: 92px;
		    &__text{
	    	    font-size: 14px;
			    line-height: 23px;
			    max-width: 670px;
			    margin: 0 auto;
			    margin-bottom: 56px;
		    }
		    &__form{
		    	width:305px;
		    }
		}
	}
}
@media(max-width:1023px){
	.photography{
		&__title{
			font-size: 48px;
			line-height: 58px;

			/* identical to box height */
			letter-spacing: -3.03571px;
		}
		&__hero{
			&__slider{
				&__dots{
					width:100%;
					overflow:auto;
					.slick-dots{
						min-width: 940px;
						li{
							min-width: 188px;
							width: 188px;
						}
					}
				}
				
				&__unit{
					padding-bottom: 70px;
					&__title{
						font-size: 36px;
						line-height: 60px;
						text-align: center;
						letter-spacing: 6px;
					}
				}

			}
			&__controls{
				&__unit{
					width: 65px;
					height: 55px;
					margin-right:25px;
				}
			}
		}
		&__text{
			font-size: 16px;
			line-height: 25px;
		}
		&__gallery{
			margin-bottom: 104px;
			max-width: 768px;
			width:calc(100% + 180px);
			position: relative;
			left:50%;
			transform:translateX(-50%);
			&__unit{
				min-height: 321px;
				width:calc(50% - 15px);
				margin-bottom: 30px;
				&__subtitle{
					font-size: 18px;
					line-height: 30px;
				    margin-bottom: 15px;
				}

				&__text{
					font-size: 16px;
					line-height: 25px;
				}
				&__title{
					left:31px;
					bottom:21px;
					font-size: 30px;
					line-height: 30px;
					letter-spacing: -1.5px;
				}
				&__link{
				    padding-right: 26px;
					font-size: 16px;
					line-height: 30px;
					&:before{
						top: 8px;
					    right: 4px;
					    width: 12px;
					    height: 12px;
					};
				}

				&__info{
				    padding: 41px 36px 33px 36px;
				}
				&:nth-child(1){
					-ms-flex-order:1;
					order:1;
				}
				&:nth-child(2){
					-ms-flex-order:2;
					order:2;
				}
				&:nth-child(3){
					-ms-flex-order:5;
					order:5;
				}
				&:nth-child(4){
					-ms-flex-order:3;
					order:3;
				}

				&:nth-child(5){
					-ms-flex-order:4;
					order:4;
				}
				&:nth-child(6){
					-ms-flex-order:6;
					order:6;
				}
			}
		}
		&__packages{
			&__title{
				margin-bottom: 35px;
				font-size: 30px;
				line-height: 30px;
				letter-spacing: -2px;
			}
			&__wrap{
				flex-direction: column;
				position: relative;
				left:50%;
				transform:translateX(-50%);
				width:calc(100% + 180px);
			}
			&__unit{
			    padding: 29px 91px 26px;
				width: 100%;
				border-right:0;
				&:nth-child(2n-1){
					border-right:none;
				}
				&:nth-child(2n){
					border-left:none;
					margin-left: 0;
				}
				&__title{
					&__plus{
						right:40px;
					}
				}
				&:nth-child(1){
					-ms-flex-order:1;
					order:1;
				}
				&:nth-child(2){
					-ms-flex-order:4;
					order:4;
				}
				&:nth-child(3){
					-ms-flex-order:2;
					order:2;
				}
				&:nth-child(4){
					-ms-flex-order:5;
					order:5;
				}

				&:nth-child(5){
					-ms-flex-order:3;
					order:3;
				}
				&:nth-child(6){
					-ms-flex-order:6;
					order:6;
				}
			}
		}
		&__person{
			position: relative;
			 min-height: 656px;
			&__image{
				position: absolute;
				top:0;
		        right: 45%;
			}
			&__info{
			   
				padding-left: 17px;
			    padding-top: 45px;

			}
			&__links{
				max-width: 198px;
		    	margin-left: 0;
		    }
			&__name{
				margin-bottom: 14px;
				font-size: 30px;
				line-height: 45px;
			}
			&__company{
				margin-bottom: 20px;
			}
			&__description{
			    margin-bottom: 18px;
			    width: calc(100% + 50px);
				font-size: 14px;
				line-height: 25px;
				p{
				    margin-bottom: 25px;
				}
			}
			&__wrap{
				position: static;
				padding-bottom: 166px;
			}
			&__image{
				margin-left: 0;
				top:0;
				width:644px;
				position: absolute;
			}
			&__button{
				position: absolute;
				bottom:40px;
				left:50%;
				transform:translateX(-50%);
			}
		}
		&__contact{
		    padding-top: 32px;
		    padding-bottom: 94px;
			&__text{
				font-size: 16px;
				line-height: 25px;
				width:590px;
				max-width: 100%;
			    margin-bottom: 27px;
			}
			&__form{
				width:382px;
			}
		}
	}
}
@media(max-width:760px){
	.photography{
		&__container{
			padding-top:0;
			padding-bottom: 97px;
		}
		&__hero{
			height:568px;
			min-height: 568px;
			&__controls{
				margin-right: -23px;
				&__unit{
					width: 50px;
					height: 50px;
					margin-right: 20px;
				}
			}
			&__slider{
				&__unit{
					height:568px;
					min-height: 568px;
					&__title{
						font-size: 24px;
						line-height: 60px;

						/* identical to box height, or 250% */
						text-align: center;
						letter-spacing: 6px;
					}
				}
				&__dots{
					.slick-dots{
						min-width: 545px;
						li{
							min-width: 109px;
							width:109px;
							&:before{

								padding-top:65.137614678899082568807339449541%;
							};
						}
					}
				}

			}
		}
		&__text{
			max-width: 580px;
			width:100%;
			font-size: 14px;
			line-height: 25px;
		}
		&__gallery{
		    margin-bottom: 93px;
			width:calc(100% + 120px);
			&__unit{
				min-height: 310px;
				max-width: 100%;
				width:calc(100vw - 130px);
				margin:0 15px;
				&__info{
				    padding: 15px 30px 15px 30px;
				}
				&__text{
					font-size: 12px;
					line-height: 16px;
				}
				&__title{
					width:calc(100% - 50px);
					max-width: 100%;
					font-size: 24px;
					line-height: 30px;
					letter-spacing: -1.5px;
					left: 25px;
    				bottom: 17px;
    				padding-right: 30px;
    				&__plus{
    					right:0;
    					display: block;
    				}
				}
			}
		}
		&__packages{
			margin-bottom: 90px;
			&__title{
			    margin-bottom: 26px;
				font-size: 24px;
				line-height: 30px;
				letter-spacing: -2px;
				.text-animated--eachWord__word{
					padding-right:2px;
				}
			}
			&__wrap{
			    width: calc(100% + 120px);
			}
			&__unit{
			    padding: 27px 60px 22px;
				&__title{
					font-size: 16px;
					line-height: 25px;
					&__plus{
					    right: -10px;
						width:25px;
						height:25px;
					}
					&:hover{
						.photography__packages__unit__title__plus{
							&:before{
								width:100%;
							};
							&:after{
								height:100%;
							};
						}
					}
				}
			}
		}
		&__person{
			&__wrap{
				padding-bottom: 50px;
				display: flex;
				flex-direction: column;
			}
			&__image{
				position: static;
				width: calc(100% + 120px);
				margin-left: -60px;
			}
			
			&__info{
				padding-top: 31px;
				width:100%;
				padding-left: 0;
			}
			&__name{
				margin-bottom: 19px;
				font-size: 24px;
				line-height: 35px;
			}
			&__company{
    			margin-bottom: 11px;
			}
			&__description{
				width: 100%;
				margin-bottom: 14px;
				p{
					&:last-child{
						padding-right:0;
					}
				}
			}
			&__links{
				margin-bottom: 30px;
				font-size: 16px;
				line-height: 30px;
				width:100%;
				max-width: 100%;
			}
			&__footer{
				flex-direction: column-reverse;
			}
			&__button{
				left:0;
				transform:none;
				position: relative;
				bottom:inherit;
			}
			
		}
		&__contact{
		    padding-top: 77px;
			&__text{
				padding:0 5px;
				font-size: 14px;
				line-height: 25px;
			    margin-bottom: 20px;
			}
		}
	}
}