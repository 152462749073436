@keyframes button-animated{
	0%{
		transform:scaleX(0);
	}
	49%{
		transform-origin: left center;	
	}
	50%{
		transform:scaleX(1);
		transform-origin: right center;
	}
	100%{
		transform:scaleX(0);
		transform-origin: right center;
	}
}
.button{
	cursor: pointer;
	position: relative;
    display: inline-flex;
	//overflow: hidden;
	color:#fff;
	&>span{
		width: 100%;
		display: block;
		position: relative;
		z-index: 2;
		border:1px solid #fff;
		
		text-align: center;
		text-transform: uppercase;
		height:60px;
		line-height: 58px;
		padding:0 24px;
		display: inline-block;
		overflow:hidden;
		will-change: opacity,color;
		transition:opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0s,color .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);

	}
	&--big-text{
		&>span{
			padding:0 26px;
			font-size: 24px;
		}
	}
	&:before{
		content:"";
		position: absolute;
		top:50%;
		left:50%;
		width:100%;
		height:100%;
		transform:translate(-50%,-50%)scale(0);
		background-color: #fff;
		will-change: opacity,transform;
		transition:transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
	};
	&:hover{
		span{
			color:#000;
		}
		&:before{
			transform:translate(-50%,-50%)scale(1);
		}
	};
	&:after{
		background-color: #fff;
		width:100%;
		height:100%;
		position: absolute;
		top:0;
		left:0;
		transform:scaleX(0);
		content:"";
		will-change: transform;
		transition:all .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
		transform-origin:left center;
	};
	&--animated{
		white-space: nowrap;
		pointer-events: none;

		//opacity:0;
		//pointer-events:none;
		&>span{
			opacity: 0;
		}
		&:before{
			opacity: 0;
		};
		&--visible{
			transition:color .3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0s,pointer-events .3s cubic-bezier(0.55, 0.085, 0.68, 0.53) .4s;
			pointer-events: all;
			&:before{
				opacity: 1;
			}
			&>span{
				transition:opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53) .4s,color .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				opacity: 1;
			}
			&:after{
				animation: button-animated .8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 1;
				animation-fill-mode: forwards;
			}
		}
	}
}
@media(max-width:1600px){
	.button{
		font-size: 13px;
		&>span{
		    height: 45px;
		    line-height: 44px;
		    padding: 0 18px;
		}
		&--big-text{
			&>span{
			    padding: 0 20px;
				font-size: 18px;
			}
		}
	}
}
@media(max-width:1023px){
	.button{
		font-size: 16px;
		&>span{
			height:60px;
			line-height: 58px;
			padding:0 24px;
		}
		&--big-text{
			&>span{
				padding: 0 26px;
				font-size: 16px;
			}
		}

	}
}
@media(max-width:760px){
	.button{
		font-size: 14px;

		&>span{
			height:40px;
			line-height: 38px;
			padding: 0 20px;
		}
		&__hide-mobile{
			display: none;
		}
		&--big-text{
			&>span{
				font-size: 14px;
			}
		}
	}
}