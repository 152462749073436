.menu{
	position: fixed;
	top:0;
	right:0;
	height:100%;
	width:100%;
	
	z-index: 10;
	pointer-events: none;
	&__wrap{
		justify-content: space-between;
		
		height: 100%;
		display: flex;
	}
	&__list{
		padding:100px 0 45px;
		z-index: 1;
		position: relative;
		width:0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color:#fff;
		font-size: 48px;
		line-height: 58px;
		font-weight: 300;
	    letter-spacing: -1.03571px;
		text-transform: uppercase;
		background-color: $black-2;
		will-change: width;
		transition: width .2s ease-in-out .7s;
		text-align: center;
		&__unit{
			overflow: hidden;
			display: flex;
			justify-content: center;
			margin-bottom: 55px;
			a{
				will-change: transform;
				transition:transform .7s cubic-bezier(.77, 0, .175, 1);
				display: inline-block;
				overflow: hidden;
				color:$grey;
				transform:translateY(104%);
				position: relative;
				padding:0 5px;
				span{
					position: relative;
					display: inline-block;	
					&:before{
						color:#fff;
						content:attr(data-text);
						position: absolute;
						top:0;
						left:0;
						
						height:100%;
						font-style: inherit;
						font-weight: inherit;
						transition: .5s cubic-bezier(.755, .05, .855, .06);
					    width: 0%;
					    white-space: nowrap;
		    			overflow: hidden;
					}
					
				}
				&:hover{
					span{
						&:before{
							width: 100%;
						};
					}
				};
			
			}
			&:last-child{
				margin-bottom: 0;
			}
			&:nth-child(1){
				a{
					transition-delay:0s;
				}

			}
			&:nth-child(2){
				a{
					transition-delay:.1s;
				}

			}
			&:nth-child(3){
				a{
					transition-delay:.2s;
				}
			}
			&:nth-child(4){
				a{
					transition-delay:.3s;
				}

			}
			&:nth-child(5){
				a{
					transition-delay:.4s;
				}

			}
			&:nth-child(6){
				a{
					transition-delay:.5s;
				}

			}
		}
		
	}
	&__logo{
		opacity: 0;
		background-color: $black-2;
		position: relative;
		width:50%;
		display: flex;
		align-items: center;
		justify-content: center;
		will-change: opacity;
		transition: opacity .1s ease-in-out .5s;
		img{
			opacity: 0;
			will-change: opacity,transform;
			transition: opacity .5s ease-in-out,transform .5s ease-in-out;
			transform:translateY(-100%);
			width:302px;
		}

	}
	&__bg{
		overflow:hidden;
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		&__unit{
			position: absolute;
			top:50%;
			width: 100%;
			height:100%;
			left:50%;
			transform:translate(-50%,-50%)scale(1.1);
			opacity: 0;
			will-change: opacity,transform;
			transition:.5s cubic-bezier(0.39, 0.575, 0.565, 1);
			&--active{
				opacity: 1;
				transition:.5s cubic-bezier(0.39, 0.575, 0.565, 1);
				transform:translate(-50%,-50%)scale(1);
				
			}
			&:before{
				z-index: 1;
				content:"";
				position: absolute;
				width: 100%;
				height:100%;
				background-color: rgba(0,0,0,0.3);
			}
			&__bg{
				will-change: transform;
				transition:.5s linear .5s;
				position: absolute;
				top:0;
				left:0;
				width: 100%;
				height:100%;
				transform-origin: bottom right;
				background-size: cover;
				background-position: center;
			}
			&:nth-child(2n-1){
				.menu__bg__unit__bg{
					transform-origin: bottom left;
				}
			}
		}

	}
	&__burger{
		pointer-events: none;
		will-change: opacity,visibility;
		transition:opacity .3s ease-in-out 0s,visibility .3s ease-in-out 0s;
		cursor: pointer;
		position: fixed;
		top: 29px;
	    right: 18px;
	    z-index: 2;
	    cursor: pointer;
	    width: 28px;
	    height: 20px;
		span{
			transition: .7s cubic-bezier(.87,-.41,.19,1.44);
			position: absolute;
			width:100%;
			height:2px;
			background-color: #fff;
			left:0%;
			transition-delay: 1.1s;
		    transform: translate3d(300%,0,0);
		    will-change:top,transform;
			&:nth-child(1){
				top:0px;
				transition:transform .7s cubic-bezier(.87,-.41,.19,1.44) 1.1s;
			}
			&:nth-child(2){
				transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) 1.3s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) 1.3s;
				top: 9px;
			}
			&:nth-child(3){
				transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) 1.2s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) 1.2s;
				top: 18px;
			}
			&:nth-child(4){
				top: 9px;
				transform: rotate(45deg) translate3d(300%,0,0);
				transition-delay: 0s;
			}
			&:nth-child(5){
				top: 9px;
				transform: rotate(-45deg) translate3d(300%,0,0);
				transition-delay: .1s;
			}
		}
		&--shown{
			pointer-events: all;
			transition:pointer-events .3s ease-in-out 1s;
			span{
				transform: translate3d(0%,0,0);

			}
		}
		&:hover{
			span{
				
				&:nth-child(2){
					width:120%;
				}
				&:nth-child(3){
					width:80%;
				}

			}
		};

	}
	&__socials{
		opacity: 0;
		visibility: hidden;
		transition: .7s cubic-bezier(.77, 0, .175, 1);
		position: absolute;
		bottom:40px;
		left:50%;
		transform:translateX(-50%);
	}
	&:after{
		background-color: $black-2;
		right:0px;
		top:0px;
		width: 100%;
		transform:scaleX(0);
		transform-origin: center right;
		height:100%;
		content:"";
		position: absolute;
		//z-index: 1;
		will-change:transform;
		transition: transform 1.5s cubic-bezier(.05, .4,.2, -4);
	};
	&--no-delay{
		.menu__burger{
			span{
				&:nth-child(1){
					top:0px;
					transition:transform .7s cubic-bezier(.87,-.41,.19,1.44) .2s;
				}
				&:nth-child(2){
					transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) .4s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) .4s;
					top: 9px;
				}
				&:nth-child(3){
					transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) .3s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) .3s;
					top: 18px;
				}
				&:nth-child(4){
					top: 9px;
					transform: rotate(45deg) translate3d(300%,0,0);
					transition-delay: 0s;
				}
				&:nth-child(5){
					top: 9px;
					transform: rotate(-45deg) translate3d(300%,0,0);
					transition-delay: .1s;
				}
			}
		}
	}
	&--active{
		pointer-events: all;
		
		&:after{
			transform:scaleX(.5);
			transition: transform 1.5s cubic-bezier(.05, .4, .2, 4);
		}
		.menu{

			&__logo{
				opacity: 1;
				img{
					opacity: 1;
					transform:translateY(0);
					transition-delay: 1.2s;
				}
			}
			&__list{
				will-change: width;
				transition: width .2s ease-in-out;
				width:50%;
				position: relative;
				&__unit{
					a{
						transition: 1.5s cubic-bezier(.23, 1, .32, 1);
						transform:translateY(0);

					}
					&:nth-child(1){
						a{
							transition-delay:.8s;
						}

					}
					&:nth-child(2){
						a{
							transition-delay:.9s;
						}

					}
					&:nth-child(3){
						a{
							transition-delay:1s;
						}
					}
					&:nth-child(4){
						a{
							transition-delay:1.1s;
						}

					}
					&:nth-child(5){
						a{
							transition-delay:1.2s;
						}

					}
					&:nth-child(6){
						a{
							transition-delay:1.3s;
						}

					}
				}
			}
			&__bg{
				&__unit{
					&--active{
						.menu__bg__unit__bg{
							transition:100s linear .5s;
							transform:scale(2);
						}
					}
				}
			}
			&__burger{
				span{
					transition-delay: 0s;
					transform: translate3d(300%,0,0);
					&:nth-child(2){
						transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) .3s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) .3s;
					}
					&:nth-child(3){
						transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) .2s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) .2s;
					}
					&:nth-child(4){
						transform: rotate(45deg);
						transition-delay: .7s;
					}
					&:nth-child(5){
						transform: rotate(-45deg);
						transition-delay: .8s;
					}
				}
			}
			&__socials{
				opacity: 1;
				visibility: visible;
				transition: .7s cubic-bezier(.77, 0, .175, 1) .8s;
			}
		}

	}


}
@media(max-width:1600px){
	.menu{
		&__logo{
			img{
				width: 225px;
			}
		}
		&__list{
			font-size: 36px;
		    line-height: 44px;
		    &__unit{
		    	margin-bottom:41px;
		    }
		}
	}
}
@media(max-width:1023px){
	.menu{
		&__logo{
			img{
				width:179px;
			}
		}
		&__list{
			letter-spacing: -2px;
		}
	}
}
@media(max-width:760px){
	.menu{
		&__logo{
			display: none;
		}
		&__burger{

			top: 25px;
    		right: 15px;

    		&:hover{
				span{
					&:nth-child(2){
						width:100%;
					}
					&:nth-child(3){
						width:100%;
					}

				}
			}
		}
		&__list{
			height:100%;
			width: 100%;
			transition: transform .1s linear 1s;
			transform:scaleX(0);
			transform-origin: center right;
			letter-spacing: 0px;
			font-size: 24px;
			line-height: 29px;
			
		}
		&__wrap{
			display: block;
		}
		&__socials{
		    bottom: 25px;
		}
		
		&:after{
			//cubic-bezier(.23, 1, .32, 1)
			transition:transform .3s cubic-bezier(.23, 1, .32, 1);
			width: 100%;

		}
		&--no-delay{
			.menu__burger{
				pointer-events: all;
				transition:pointer-events .3s ease-in-out 1s;
				span{
					transform: translate3d(0%,0,0);

				}
			}
		}
		&--active{
			&:after{
				transition: transform 1s cubic-bezier(.23, 1, .32, 1);
				transform:scaleX(1);
			}
			.menu{
				&__list{
					transition: transform .1s linear .6s;
					width:100%;
					transform:scaleX(1);
					
				}
				
				
			}
			.menu{
				&__burger{
					span{
						transition-delay: 0s;
						transform: translate3d(300%,0,0);
						&:nth-child(2){
							transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) .3s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) .3s;
						}
						&:nth-child(3){
							transition: transform .7s cubic-bezier(.87,-.41,.19,1.44) .2s,width .2s ease-in-out 0s,-webkit-transform .7s cubic-bezier(.87,-.41,.19,1.44) .2s;
						}
						&:nth-child(4){
							transform: rotate(45deg);
							transition-delay: .7s;
						}
						&:nth-child(5){
							transform: rotate(-45deg);
							transition-delay: .8s;
						}
					}
				}
			}
		}
	}
}
@media(max-width:350px){
	.menu{
		&__list{
			padding-top: 20px;
		}
	}
}