@keyframes delay-pointer-events {
    0% {
        pointer-events: none;
    }
    99% {
       	pointer-events: none;
    }
    100% {
        pointer-events: all;
    }
}
.nav{
	width: 100px;
    padding: 48px 35px 0;
	overflow:hidden;
	position: fixed;
	height:100%;
	left:0;
	background-color: rgba(15, 16, 18, .5);
	z-index: 3;
	color:#fff;
	transform:translateX(-100%);
	will-change: opacity,width,transform,background-color;
    transition:opacity .5s ease-in-out 2.5s,width .5s ease-in-out 0s,transform .5s ease-in-out 2.5s,background-color .5s ease-in-out;
	justify-content: center;
	display: flex;
	flex-direction: column;
	opacity: 0;
	&__logo{
		display: block;
		pointer-events: none;
	    position: relative;
	    transform:translateX(-18px);
		width: 66px;
		will-change: transform;
		transition: transform .5s ease-in-out,margin .5s ease-in-out,pointer-events .5s ease-in-out .1s;
		transform-origin:top left;
		img{

			display: block;
			width: 100%;
		}
	}
	&__menu{
		pointer-events: none;
		font-size: 26px;
		line-height: 31px;
		font-weight: 300;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding:0;
		margin:0;
		width:241px;
		list-style: none;
		flex:1;
		&__unit{
			cursor: pointer;
			opacity: 1;
			padding-left: 60px;
			position: relative;
			margin-bottom: 16px;
			
			a{
				will-change: opacity,transform;
				display: inline-block;
				transition: opacity .5s ease-in-out,transform .5s ease-in-out;
				transform:translateX(20px);
				opacity: 0;
			}
			&__icon{
				font-size: 0;
				line-height: 0;
				top:50%;
				transform:translateY(-50%);
				left:12px;
				transition: background-color .5s ease-in-out;
				position: absolute;
				margin:0 auto;
				display: block;
				width:6px;
				height:6px;
				min-height: 6px;
				min-width: 6px;
				border-radius: 6px;
				background-color:#fff;
				svg{
					overflow: visible;
					position: absolute;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%)rotate(-90deg);
					circle{
						will-change: stroke-dasharray,stroke-dashoffset;
						transition: .8s cubic-bezier(.39,.575,.565,1) 0s;
						stroke-dasharray: 50px;
		    			stroke-dashoffset: 50px;
					}
				}
				&:before{
					background-color: #c4c4c4;
					position: absolute;
				    bottom: calc(100% + 4px);
					left:50%;
					transform:translateX(-50%)scaleY(0);
					transform-origin: center top;
					content:"";
					width:1px;
					height: 32px;
					transition:transform .5s ease-in-out .8s;
				};
			}
			&--small{
				font-size: 18px;
				
				&:before,&:after{
					top:9px;
					width:16px;
					left:15px;
				}
				.nav__menu__unit__icon{
					background-color:  #969696;
					
				}
				a{
					display: inline-block;
					padding-left: 15px;
				}

			}
			
			
			&--active{
				a{
					opacity: 1;
				}
				.nav__menu__unit__icon{
					
					svg{
						circle{
							transition: .8s cubic-bezier(.39,.575,.565,1) .5s;
							stroke-dashoffset: 0;
						}
					}
					&:before{
						transition:transform .5s ease-in-out 0s;
						transform:translateX(-50%)scaleY(1);
					};
				}
				
			}
			&:hover{
				a{
					opacity: 1;
				}
			}
			&--prev{
				.nav__menu__unit__icon{
					
					svg{
						circle{
							transition: .8s cubic-bezier(.39,.575,.565,1) .5s;
							stroke-dashoffset: 0;
						}
					}
					&:before{
						transition:transform .5s ease-in-out 0s;
						transform:translateX(-50%)scaleY(1);
					};
				}
			}
			&:last-child{
				margin-bottom: 0;
			}
			&:first-child{
				.nav__menu__unit__icon:before{
					display: none;	
				}
			}

		}
		&__submenu{
			margin:12px 0;
		}
	}
	&--hover{
		width: 321px;
		.nav{
			&__menu{
				pointer-events: all;
				&__unit{
					a{
						opacity: .7;
						transform:translateX(0);
						
					}
					&:hover{
						a{
							opacity: 1;
						}
					}
					&--active{
						a{
							opacity: 1;
						}
					}
				}
			}
			&__logo{

				pointer-events: all;
				transform:scale(1.5909090909090909090909090909091)translateX(38px);
			}
		}
	};
	&--shown{		
		opacity: 1;
		transform:translateX(0);
	}
	&--filled{
		background-color: rgba(15, 16, 18, 1);
	}
	&--work{
		transition:opacity .5s ease-in-out .5s,width .5s ease-in-out 0s,transform .5s ease-in-out .5s,background-color .5s ease-in-out;
		.nav{
			&__menu{
				width:300px;
			}
		}
		&.nav--hover{
			width:380px;
			.nav__logo{
				transform:scale(1.5909090909090909090909090909091)translateX(63px);
			}
		}
	}

}
@media(max-width:1600px){
	.nav{
		padding: 36px 26px 0;
		width:75px;
		&__logo{
			transform:translateX(-13px);
			width: 49px;
		}
		&__menu{
			font-size: 20px;
		    line-height: 23px;
		    width: 180px;
		    &__unit{
		    	padding-left: 45px;
			    margin-bottom: 12px;
			    &:before,&:after{
			    	width: 23px;
				    top: 11px;
			    }
			    &__icon{
			    	left:9px;
			    	&:before{
			    		height:21px;
			    	};
			    }
			    &--small{
		    	    font-size: 14px;
    				
    				
    				
    				    
			    }
			    
			    
		    }
		}
		&--hover{
    		width: 240px;
    		.nav{
    			&__logo{
				    transform:scale(1.5909090909090909090909090909091)translateX(33px);
    			}

    		}
		};
		&--work{
			.nav{
				&__menu{
					width: 235px;
				}
			}
			&.nav--hover{
				width: 287px;
				.nav__logo{
					transform: scale(1.59091) translateX(50px);
				}
			}
		}
	}
}
@media(max-width:1023px){
	.nav{
	    padding: 47px 26px 0;
	    
	}
}
@media(max-width:760px){
	.nav{
		background-color: transparent;
		padding: 60px 10px 38px;
		width:50px;
		overflow:visible;
		overflow:visible;
		&--hover{
			padding: 60px 10px 38px;
			width:40px;
			.nav{
				width:40px;
				&__logo{
					width: 35px;
					transform:scale(1);
					margin-left: 0;
				}
			}
		}
		&__menu{
			pointer-events: all;
			width:20px;
			overflow-x:hidden;
			overflow-y:auto;
			&__unit{
				width: 100%;
				padding-left: 0;
				height:7px;
				margin-bottom: 24px;
				a{
					display: none;
				}
				&:before,&:after{
					display: none;
					top:4px;
					width:20px;
				}
				&__icon{
					left:0px;
					position: relative;
					&:before{
						height:17px;
					}
				}
				&--small{
					
				}
				&--hide-mobile{
					display: none;
				}
				
				
				
				
			}
		}
		&--filled{
			background-color: transparent;
		}
		&__logo{
			top: 25px;
		    left: 3px;
		    position: fixed;
		    width: 35px;
			transform:scale(1);
			margin-left: 0;
		}
		&--work{
			.nav__menu{
				width:20px;
			}
			&.nav--hover{
				width:50px;
				.nav__logo{
				    transform: scale(1);
				}
			}
		}
	}
}