.container{
	margin:0 auto;
	width:calc(100% - 300px);
	max-width: 1300px;
	padding:0 10px;
	&--full-height{
		height:100%;
	}
	&--relative{
		position: relative;
	}
	&--wide{
		width: 100%;
		max-width: 1920px;
	}
}
@media(max-width:1600px){
	.container{
		margin:0 auto;
		width:calc(100% - 200px);
		max-width: 980px;
		padding:0 10px;
		&--wide{
			width: 100%;
			max-width: 100%;
		}
	}
}
@media(max-width:1023px){
	.container{
		width:calc(100% - 200px);
		max-width: 100%;
		&--wide{
			//width: 100%;
			//max-width: 100%;
		}
		&--simple-page{
			width:calc(100% - 160px);
		}
	}
}
@media(max-width:760px){
	.container{
		width:calc(100% - 100px);
		&--wide{
			//width: 100%;
			//max-width: 100%;
		}
	}
}