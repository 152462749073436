/* animation delays timings */




@keyframes logo {
  0% {
    stroke-width: 1pt;
    stroke-dashoffset: 1200;
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
    stroke-width: 0pt;
  }
}

/*
@keyframes intro-logo{
	0%{
		transform:scale(1);
		transform-origin: 50% 50%;
	}
	95%{
		transform:scale(100);
		opacity: 1;
		visibility: visible;
		transform-origin: 50% 50%;
	}
	100%{
		transform:scale(1);
		opacity: 0;
		visibility: hidden;
		transform-origin: 50% 50%;
	}
}
*/
@keyframes intro-line{
	0%{
		transform:scaleY(0);
	}
	5%{
		transform:scaleY(0);
	}
	49%{
		transform-origin: top center;	
	}
	50%{
		transform:scaleY(1);
		transform-origin: bottom center;
	}
	95%{
		transform:scaleY(0);
		transform-origin: bottom center;
	}
	100%{
		transform:scaleY(0);
		transform-origin: bottom center;
	}
}
@keyframes intro-logo{
	0%{
		transform:scale(1);
	}
	98%{
		transform:scale(100);
		opacity: 1;
		visibility: visible;
	}
	99%{
		transform:scale(100);
		opacity: 0;
		visibility: hidden;
	}
	100%{
		transform:scale(1);
		opacity: 0;
		visibility: hidden;
	}
}
.intro{
	&__video{
		&:before{
			transition-delay:2.7s;

		}

	}
	&__logo{
		path{
			
		}
	}
	&__title{
		transition-delay: 3s,3s;
	}

	&__scroll{
		transition-delay: 5.6s,5.6s;
	}
	.fp-scroller{
		height:100%;
	}

}
.intro{

	position: relative;
	overflow:hidden;
	&__video{
		overflow: hidden;
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height:100%;
		video{
			object-fit: cover;
			position:absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			min-width: 100%;
			min-height:100%;
		}
		&:before{
			will-change: opacity;
    		transition: opacity .5s ease-in-out 2s;
			z-index: 1;
			content:"";
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background-color: #fff;

		}
		&:after{
			will-change: opacity;
			opacity: 0;
			transition-property: opacity;
    		transition-duration: 3s;
    		transition-timing-function: ease-in-out;
			z-index: 1;
			content:"";
			position: absolute;
			top:0;
			left:0;
			right:0;
			bottom:0;
			background-color: rgba(0,0,0,.3);
		}
	}
	&__overlay{
		
		position: relative;
		//top:50%;
		//left:50%;
		
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		height:100%;
		width: 100%;
		z-index: 1;
		svg{
			width:547px;
			//position: absolute;
		    //top: calc(50% - 32px);
			//left:50%;
			//transform:translate(-50%,-50%);
			

		}

		&__side{

			width:calc((100% - 540px)/2);
			position: absolute;
			background-color: $black-bg;
			height: calc(100% + 2px);
			top:-1px;
			&:before{
				content:"";
				width:100vw;
				height:calc((100% - 268px)/2 - 32px);
				position: absolute;
				background-color: $black-bg;
			};
			&-left{
				left:0;
				&:before{
					top:0;
					left:0;
				};
			}
			&-right{	
				width:calc((100% - 540px)/2);
				right: 0;
				&:before{
					height:calc((100% - 268px)/2 + 32px);
					bottom:0;
					right:0;
				}
			}
		}
		
	}
	&__info{
		padding-top: 50px;
		font-size: 16px;
		line-height: 32px;
		text-align: center;
		display: flex;
		flex-direction:column;
		align-items: center;
		z-index:2;
		bottom:0;
		left:0;
		width: 100%;
		color:#fff;
	}
	&__logo{
		transform:scale3d(1,1,1);
		position: absolute;
		width: 100%;
		height: 100%;
		margin:auto;
		top:0;
		left:0;
		bottom:0;
		right:0;
		path{
			will-change: fill-opacity;
			transition:fill-opacity .5s ease-in-out;
			fill-opacity:0;
			fill: #000;
			stroke: #000;
			stroke-width: 0;
			
			animation-fill-mode: forwards;
		}
		&__wrap{
			min-width:587px;
			min-height:291px;
			z-index: 1;
			display: flex;
			position: relative;
			margin:auto 0;
			transform-origin: center center;

		}
		&__side{

			width:calc((100% - 540px)/2);
			position: absolute;
			background-color: $black-bg;
			height: 400vh;
			width:400vw;
			
			
			
			&--top{
				left:50%;
				transform:translateX(-50%);
				bottom:calc(100% - 3px);
			}
			&--bottom{
				left:50%;
				transform:translateX(-50%);
				top:calc(100% - 3px);
			}
			&--left{
				top:50%;
				transform:translateY(-50%);
				    right: calc(100% - 4pt);
				
			}
			&--right{	
				top:50%;
				transform:translateY(-50%);
				left:calc(100% - 4pt);
				
			}
		}
	}
	&__menu{
	    margin-bottom: 80px;
		display: flex;
		justify-content: center;
		opacity: 0;
		visibility: hidden;
		&__unit{
			margin-right: 66px;
			position: relative;;
			
			&:after{
				content:"";

				position: absolute;
				top:14px;
				
				left:calc(100% + 30px);
				width:6px;
				height:6px;
				background-color:#D8D8D8;
			}
			&:last-child{
				margin-right: 0;
				&:after{
					display: none;
				}
			}
		}
	}
	&__title{
		position: absolute;
		top:calc(50% + 185px + 20px);
		left:0;
		width:100%;
		text-align: center;
		filter:blur(10px);
		transform:scale(.7);
		opacity: 0;
		margin-bottom: 36px;
		font-weight: 300;
		font-size: 28px;
		line-height: 34px;
		text-transform: capitalize;
		will-change: filter,transform;
		&__second{
			will-change: width;
			white-space: nowrap;
			display: inline-block;
			width:0;
			transition:width 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3.6s;
			overflow: hidden;
			
		}
	}
	&__scroll{
		cursor: pointer;
		opacity: 0;
		visibility: hidden;
		padding-bottom: 81px;
		text-transform: uppercase;
		position: relative;
		span{
			display: inline-block;
			will-change: transform;
			transition:transform .3s ease-in-out;
			padding:0 10px;
			display: inline-block;
			border:1px solid #fff;
		}
		&--work{
			will-change: opacity,visibility;
			transition:opacity .4s ease-in-out,visibility .3s ease-in-out;
		    font-size: 24px;
	        left: 91px;
		    position: absolute;
		    transition-delay: 0s;
	        display: inline-block;
	        bottom:0;
	        &:hover{
				span{
					transform:translateY(10px);
				}
			}
		}
		&__line{
			overflow:hidden;
			position: absolute;
			bottom:0;
			width: 1px;
		    height: 66px;
			left:50%;
			transform:translateX(-50%);
			&:before{
				transform-origin: top center;
				will-change: transform;
				animation: intro-line 2s ease-in-out 0s infinite;
				content:"";
				width: 100%;
				height:100%;
				transform:scaleY(0);
				position: absolute;
				top:0;
				left:0;
				background-color: #fff;
			};
		}
		&--shown{
			opacity: 1;
			visibility: visible;
		}
	}
	&__description{
		color:#fff;
		margin-left: auto;
		will-change: opacity,visibility;
		transition: opacity .3s ease-in-out,visibility .3s ease-in-out;
		align-items: flex-start;
		max-width: 473px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height:100%;
		opacity: 0;
		visibility: hidden;
	    padding-bottom: 110px;
		&__text{
			font-size:65px;
			line-height: 65px;
			margin-bottom: 50px;
		}
		&__button{
			width:188px;
		}
		
		&__arrow{
			transition:all .7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
			width:24px;
			height:35px;
			position: absolute;
			left:50%;
			transform:translateX(-50%)translateY(100px);
			bottom:30px;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			cursor: pointer;
			

			svg{
				width:24px;
				height:14px;
				opacity: .7;
				position: absolute;
				bottom:0;
				left:50%;
				transform:translateX(-50%);
				will-change: opacity,transform;
				transition:transform .5s cubic-bezier(.2,.3,0,.85),opacity .2s cubic-bezier(.2,.3,0,.85);
			}
			&:before{
				opacity: 0;
				content:"";
				bottom:35px;
				width: 4px;
			    height: 4px;
			    border-radius: 50%;
			    background: #fff;
			 	position: absolute;
			 	left:50%;
			 	transform:translateX(-50%);
			 	will-change: opacity,transform,bottom;
			    transition: bottom .5s cubic-bezier(.2,.3,0,.85),transform .5s cubic-bezier(.2,.3,0,.85),opacity .2s cubic-bezier(.2,.3,0,.85);
			};
			&--active{
				transform:translateX(-50%)translateY(0px);
			}
			&:hover{
				svg{
					opacity: 1;
					transform:translateX(-50%)translateY(4px)scale(1.05);
				}
				&:before{
					opacity: 1;
					bottom:20px;
					transform:translateX(-50%)scale(1.1);
				}
			};
			
		}
		
	}
	&__wrap{
		padding-top: 223px;
		height:100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items:center;
		//min-height: 100vh;
	}
	&__container{
		z-index: 1;
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}
	&--loaded{
		.intro{
			&__video{
				&:before{
					opacity: 0;
				}
			}
			&__logo{
				path{
					animation: logo 3s linear 1;
					fill-opacity:1;
				}
			}
			&__scroll{
				will-change: opacity,visibility,text-shadow;
				opacity:1;
				visibility: visible;
				transition: opacity .4s ease-in-out 2s,visibility .4s ease-in-out 2s;
				&:hover{
					span{
						transform:translateY(10px);
					}
				}
			}
			
			&__title{
				will-change: opacity,visibility,filter,transform;
				visibility: visible;
				transition:all .7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
				opacity: 1;
				filter:blur(0px);
				transform:scale(1);
			}
		}
	}
	&--animated{
		.intro{
			&__logo{
				&__wrap{
					will-change: transform,opacity,visibility;
					animation: intro-logo 2s cubic-bezier(0.755, 0.05, 0.855, 0.06) 0s 1;
					animation-fill-mode: forwards;
				}
			}
			&__overlay{
				will-change: transform;
				transition:transform 2s cubic-bezier(0.755, 0.05, 0.855, 0.06);
				transform:translate(-50%,-50%)scale3d(100,100,100);
			}
			&__info{
				will-change:opacity,visilibity;
				transition:opacity .2s ease-in-out,visibility .2s ease-in-out;
				opacity: 0;
				visibility: hidden;
				z-index: 1;
			}
			&__description{
				
				transition-delay:2.2s;
				opacity: 1;
				visibility: visible;
				
				&__arrow{
					cursor: pointer;
					transition-delay: 2.5s;
					transform: translateX(-50%) translateY(0px);
					
				}
				
			}
			&__video{
				&:after{
					opacity: 1;
				}
			}
		}
	}

}
@media(max-width:1600px){
	.intro{
		&__logo{
			&__wrap{
				height:208px;
				width:410px;
			}
			&__side{
				
			}
		}
		&__wrap{
			padding-top: 150px;
		}
		&__title{
		    top:calc(50% + 185px + 10px);
			font-size: 21px;
			line-height: 26px;
		}
		&__menu{
			margin-bottom: 61px;
			font-size: 12px;
			line-height: 24px;
			&__unit{
				margin-right: 50px;
				&:after{
				    top: 10px;
				    left: -webkit-calc(100% + 30px);
				    left: calc(100% + 19px);
				    width: 5px;
				    height: 5px;
				}
			}
		}
		&__scroll{
			padding-bottom: 59px;
			font-size: 12px;
			line-height: 24px;
			&__line{
				height: 49px;
			}
			&--work{
			    font-size: 18px;
   				line-height: 29px;
			    left: 69px;
			}
		}
		&__description{
		    max-width: 355px;
		    
			&__arrow{
				width: 18px;
    			height: 26px;
				svg{
					width:18px;
					height:11px;
				}
				&:before{
					bottom:26px;
					height:3px;
					width: 3px;
				};
				&:hover{
					&:before{
					    bottom: 15px;
					}
				}
			}
			
			&__title{

			}
			&__text{
			    font-size: 49px;
			    line-height: 49px;
			    margin-bottom: 37px;
			}
		}
		&--loaded{
			.intro{
				&__title{
					&__second{
					    width: 255px;
					}
				}
			}
		}
	}
}

@media(max-width:1023px){
	.intro{
		&__logo{
			&__wrap{
				margin-top:auto;
				margin-bottom: auto;
			}
		}
		&__wrap{
			padding-top: 160px;
		}
		&__menu{
			margin-bottom: 108px;
		    font-size: 16px;
		    line-height: 24px;
			&__unit{
			    margin-right: 37px;
			    &:after{
			    	top: 9px;
				    left: calc(100% + 16px);
				    width: 6px;
				    height: 6px;
			    }
			}
		}
		&__title{
			font-size: 24px;
   			line-height: 26px;
		}
		&__scroll{
	        padding-bottom: 84px;
		    font-size: 16px;
		    line-height: 24px;
		    &:before{
		    	height: 66px;
		    }
		    &--work{
		    	left: 18px;
		    }
		}
		&--loaded{
			.intro{
				&__title{
					&__second{
					    width: 295px;
					}
				}
			}
		}
	}
}
@media(max-width:760px){
	.intro{
		&__title{
			font-size: 14px;
			position: absolute;
			top: calc(50% + 19.8vw + 25px);
			padding:0px;
			margin-bottom: 19px;
			display: block;
			line-height: 22px;
			

		}
		&__wrap{
			padding-top: 131px;
		}
		&__logo{
			height:calc(100% - 2px);
			&__wrap{
				width:80vw;
				height:39.618138424821002386634844868735vw;
				min-width: 80vw;
				min-height:39.618138424821002386634844868735vw;
			}
			&__side{
				&--left{
					right: calc(100% - 4pt);
				}
				&--top{
					bottom:calc(100% - 1pt);
				}
				&--bottom{
					bottom:calc(100% - 1pt);
				}
				&--right{
					left: calc(100% - 4pt);
				}
			}
		}
		&__description{
			&__title{
				letter-spacing: 1px;
			}
			&__text{
				margin-bottom: 0;
				font-size: 32px;
    			line-height: 35px;
			}
			&__arrow{
				display: none;
			}
		}
		&__scroll{

		    padding-bottom: 49px;
			font-size: 12px;
			line-height: 25px;
			text-transform: none;
			&:before{
			    height: 35px;
			}
			
			&--work{
			    left: calc(100% - 129px);
			    bottom:30px;
			}
		}
		&__menu{
			margin-bottom: 23px;
			font-size: 14px;
			line-height: 32px;
			flex-wrap:wrap;
			justify-content: space-between;
			&__unit{
				margin-bottom: 6px;

				width:calc(50% - 18px);
				margin-right: 0;
				&:nth-child(2n-1){
					text-align: right;
				}
				&:nth-child(2n){
					text-align: left;
					&:after{
						display: none;
					}
				}
				&:after{
				    top: 14px;
				    left: calc(100% + 14px);
				};
			}
		}
		&--loaded{
			.intro{
				&__scroll{
					&:hover{
						span{
							transform:translateY(5px);
						}
					}
				}
			}
		}
	}
}
