#result__bg{
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	height:100%;
}
video::-internal-media-controls-overlay-cast-button {
    display: none;
}
.hide--except-mobile{
	display: none;
}
.homepage{
	&__section{
		//min-height:100vh;
		overflow: hidden;
		//position: relative;
		color:#fff;

		&__wrap{
			height:100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 100vh;
			&--spread{
				justify-content: space-between;
			}
			&--alignTop{
				padding-top: 74px;
				justify-content: flex-start;
			}
			&--work-intro{
				padding-top: 156px;
				justify-content: flex-start;
			}

		}
		&__pretitle{
			padding-top: 17px;
			font-size: 24px;
			line-height: 30px;
			text-transform: uppercase;
			position: relative;
			color:$grey-2;
			margin-bottom: 29px;
			&:before{
				content:"";
				position: absolute;
				top:0;
				left:0;
				width:34px;
				height:1px;
				background-color: #fff;
			};
		}
		&__title{
			margin-bottom: 30px;
			font-weight: 300;
			font-size: 85px;
			line-height: 90px;
			letter-spacing: -3.03571px;
			
			&--special{
				width: calc(100% + 20px);
			}
			&--team{
				margin:0 auto;
				width:calc(100% - 300px);
				max-width: 1300px;
				padding:0 10px;
			}
			&--video{
				margin-bottom: 21px;
			}
			&--result{
				margin-bottom: 70px;
			}
			&__alt{
				display: none;
				position: absolute;
			}
		}
		&__info{
			z-index: 1;
			position: relative;
			&__divider{
			    margin-top: 30px;
    			margin-bottom: 45px;
				width:79px;
				height:1px;
				background-color: #fff;
			}
			&--about{
				max-width: 652px;
			}
			&--mission{
				max-width: 515px;
			}
			&--vision{
				max-width: 436px;
			}
			&--work{
				
				max-width: 575px;
				&-intro{
					flex:1;
					max-width: 660px;
				}
				&--itc{
					padding-bottom: 140px;
				}
				&--prism{
					padding-bottom: 90px;
				}
				&--fc{
					padding-bottom: 73px;
				}
				&-dcc{
					padding-bottom: 60px;
				}
				&--workpage{
					padding-bottom: 100px;
				}
			}
			&--photography{
				max-width: 650px;
			}
		}
		&__text{
			margin-bottom: 35px;
			p{
				margin-top:0;
				margin-bottom: 34px;
				&:last-child{
					margin-bottom: 0;
				};
			}
			&__unit{
				width: calc(50% - 15px);
			}
			
			&--double{
				margin-bottom: 98px;
				max-width:100%; 
				width: 100%;
				justify-content: space-between;
				display: flex;
				
			}
			&--video{
				max-width: 682px;
				margin:0 auto;
				margin-bottom: 20px;
			}
			&:not(.homepage__section__text):last-child{
				margin-bottom: 0;
			}
		}

		&__comment{
			font-size: 24px;
			line-height: 38px;
			margin-bottom: 35px;
			a{
				position: relative;
				&:before{
					position: absolute;
					top:15px;

					left:-43px;
					content:"";
					width:8px;
					height:8px;
					transform:rotate(45deg);
					border-top:2px solid #D8D8D8;
					border-right:2px solid #d8d8d8;
					//border-left:2px solid transparent;
					//border-bottom:2px solid transparent;
				};
			}
			&--video{
				margin-bottom: 14px;
			}
			&--result{
				line-height: 35px;
				max-width: 684px;
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 62px;
			}
		}
		&__cards{
			position: relative;
			z-index: 1;
			padding-top: 25px;
			flex-wrap:wrap;
			display: flex;
			justify-content: space-between;
			max-width: 733px;
			margin-bottom: -100px;
			width:calc(100% - 436px - 40px);
			&__unit{
				outline:none;
				margin-bottom: 120px;
				max-width: 327px;
				width:calc(50% - 20px);
				will-change: opacity,transform;
				transition:opacity .5s cubic-bezier(0.39, 0.575, 0.565, 1), transform .5s cubic-bezier(0.39, 0.575, 0.565, 1);
				opacity: 0;
				transform:translateY(20%);
				&__icon{

					width:55px;
					margin-bottom: 30px;
					height:75px;
					display: flex;
					align-items: flex-end;
					img{
						display: block;
						width: 100%;
					}
					&--01{
						width:56.89px;
						img{
							//transform:translateY(2px);
						}
					}
					&--02{
						width:55.31px;
						img{
							//transform:translateY(-5px);
						}
					}
					&--04{
						img{
							//transform:translateY(8px);
						}
					}

				}
				&__title{
					font-size: 30px;
					line-height: 36px;
					letter-spacing: -1.5px;
					font-weight: 300;
					margin-bottom: 15px;
				}
				&__description{
					font-size: 16px;
					line-height: 20px;
					color:$grey-2;
				}
				&--shown{
					transform:none;
					opacity: 1;
					&:nth-child(1){
						transition-delay: .2s;
					}
					&:nth-child(2){
						transition-delay: .3s;
					}
					&:nth-child(3){
						transition-delay: .4s;
					}
					&:nth-child(4){
						transition-delay: .5s;
					}
				}
			}
			
		}
		
		&__horizontal{
			display: flex;
			justify-content: space-between;
		}
		&__steps{
			display: flex;
			flex-wrap:wrap;
			justify-content: 	space-between;
			position: relative;
			width:calc(100% + 30px);
			margin-left: -15px;
			&__unit{
				
				position: relative;
				width:33.3%;
				text-align: center;
				&__wrap{
					max-width: 100%;
					opacity: 0;
					transform:translateY(20%);
					will-change: transform,opacity;
					transition:transform .5s cubic-bezier(0.39, 0.575, 0.565, 1),opacity .5s cubic-bezier(0.39, 0.575, 0.565, 1);
					width:300px;
					&--centered{
						margin:0 auto;
					}
				}
				&__text{
					color:$grey-2;
					font-size: 16px;
					line-height: 20px;
				}
				&__title{
					font-size: 30px;
					line-height: 36px;
					font-weight: 300;
					margin-bottom: 15px;
				}
				&__icon{
					max-height: 63px;
					margin-bottom: 31px;
				}
				&:nth-child(1){
					padding-bottom: 115px;
					order:1;
					-ms-flex-order:1;
				}
				&:nth-child(2){
					order:2;
					-ms-flex-order:2;
				}
				&:nth-child(3){
					order:3;
					-ms-flex-order:3;
					padding-left: 30px;
				}
				&:nth-child(4){
					order:6;
					-ms-flex-order:6;
					padding-left: 30px;
					.homepage__section__steps__unit__icon{
						img{
							transform: translateY(-15%);
						}
					}
				}
				&:nth-child(5){
					order:5;
					-ms-flex-order:5;
					.homepage__section__steps__unit__icon{
						img{
							transform: translateY(-8%);
						}
					}
				}
				&:nth-child(6){
					order:4;
					-ms-flex-order:4;
				}
				
				&__line{
					position: absolute;
					will-change: transform;
					transition:transform .5s cubic-bezier(0.39, 0.575, 0.565, 1);
					height:2px;
					background-color: #979797;
					&__corner{
						position: absolute;
						
						overflow: hidden;
						&:before{
							box-sizing: border-box;
							
							content:"";
    						position: absolute;
    						top:0;
    						left:0;
    						width: 100%;
    						height:100%;
    						
    					};
						&--top{
							width:40px;
							height:40px;
					        bottom:100%;
    						right: 0px;
    						&:before{
    							border-radius:0 100% 0 0;
    							border-top:2px solid #979797;
								border-right:2px solid #979797;
    						};

						}
						&--bottom{
							width:30px;
							height:30px;
					        top:100%;
    						right: 0px;
    						&:before{
    							border-radius:0 0% 100% 0;
    							border-bottom:2px solid #979797;
								border-right:2px solid #979797;	
    						};
						}
						
					}
					&--01,&--02,&--05,&--04{
						transform-origin: left center;
						left:calc(100% - (100% - 300px) + 30px);
						top:44px;
						width:calc((100% - 300px) + 20px);
					}
					&--02,&--04{
						left:calc(100% - (100% - 300px) + 60px);
					}
					&--04,&--05{
						transform-origin: right center;
					}
					/*

					&--04,&--05{
						transform-origin: right center;
						top:41px;
						width:calc((100% - 300px) - 30px);
					}
					&--04{
						left:calc(100% - (100% - 300px) + 85px);
					}
					&--05{
						left:calc(100% - (100% - 300px) + 55px);

					}
					*/
					&--03{
						width:2px;
					    height: 214px;
					    right: 14px;
    					top: 83px;
    					&:before{
    						will-change: height;
							transition:height .5s cubic-bezier(0.39, 0.575, 0.565, 1);
							content:"";
							background-color: $black-bg;
							height:calc(100% + 80px);
							width:40px;
							position: absolute;
							bottom:-40px;
							right:0;
							z-index: 1;
						}
						
					}
					&:not(.homepage__section__steps__unit__line--03){
						transform:scaleX(0);
					}
				}
				&--shown{
					
					.homepage__section__steps__unit__wrap{
						transform:translateY(0);
						opacity: 1;
					}
					&.homepage__section__steps__unit{
						&:nth-child(1){
							.homepage__section__steps__unit__wrap{
								transition-delay: .5s;
							}
						}
						&:nth-child(2){
							.homepage__section__steps__unit__wrap{
								transition-delay: .5s;
							}
						}
						&:nth-child(2){
							.homepage__section__steps__unit__wrap{
								transition-delay: 1s;
							}
						}
						&:nth-child(3){
							.homepage__section__steps__unit__wrap{
								transition-delay: 1.5s;
							}
						}
						&:nth-child(4){
							.homepage__section__steps__unit__wrap{
								transition-delay: 2s;
							}
						}
						&:nth-child(5){
							.homepage__section__steps__unit__wrap{
								transition-delay: 2.2s;
							}
						}
						&:nth-child(6){
							.homepage__section__steps__unit__wrap{
								transition-delay: 2.6s;
							}
						}
					}
					.homepage__section__steps__unit__line{
						&:not(.homepage__section__steps__unit__line--03){
							transform:scaleX(1);
						}
						&.homepage__section__steps__unit__line{
							
							&--01{
								transition-delay: .5s
							}
							&--02{
								transition-delay: 1s;
							}
							&--03{
								&:before{
									transition-delay: 1.5s;
									height:0;
								}
							}
							&--04{
								transition-delay: 2s;
							}
							&--05{
								transition-delay: 2.5s;
							}

						}

					}

				}
				
			}
		}
		&__media{
			position: absolute;
			
			opacity: 0;
			will-change: opacity,transform;
			transition:opacity .5s cubic-bezier(0.39, 0.575, 0.565, 1), transform .5s cubic-bezier(0.39, 0.575, 0.565, 1);
			pointer-events: none;
			&__wrap{
				position: absolute;
				top:0;
				right:0;
				height:100%;
			}
			&__content{
				background-size: cover;
				display: block;
				max-width: 100%;
				&--aboutus{
					max-height: 100vh;
				    transform: translateY(14.9%) translateX(7%);
				}
				&--vision{
				    transform: translateX(-28%) translateY(1.5%);
				}
				&__frame{
					position: absolute;
					top:0;
					left:0;
					width: 100%;
					height:100%;
					background-size: cover;
				}
				video{
					width:100%;
					max-width: 100%;
					position: absolute;

				}
				&--work-intro{
					width:56.458333333333333333333333333333vw;
					max-width: 1084px;
				    transform: translateY(.3%);
				}
				&--itc{
					&--01{
						width:100%;
						&:before{
							content:"";
							display: block;
							padding-top:53.055555555555555555555555555556%;
						}
						video{
							left:3.340757238307349665924276169265%;
							top:4.304160688665710186513629842181%;
							width:93.31848552338530066815144766147%;

						}
					}
					&--02{
						width: 100%;
					    transform: translate(3.5%,14%);
						&:before{
							content:"";
							display: block;
							padding-top:76.503340757238307349665924276169%;
						}
						video{
							left:3.340757238307349665924276169265%;
							top:4.304160688665710186513629842181%;
							width:93.31848552338530066815144766147%;

						}
					}
					&--03{
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:124.90974729241877256317689530686%;
						}
					}
				}
				&--fc{
					&--01{
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:76.503340757238307349665924276169%;
						}
						video{
							left:3.340757238307349665924276169265%;
							top:4.304160688665710186513629842181%;
							width:93.31848552338530066815144766147%;

						}
					}
					&--02{
						transform: translate(0,12%);
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:80.474452554744525547445255474453%;
						}
					}
					&--03{
						transform: translate(0,47%);
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:76.503340757238307349665924276169%;
						};
						video{
							left:3.340757238307349665924276169265%;
							top:4.127579737335834896810506566604%;
							width:93.31848552338530066815144766147%;

						}
					}
				}
				&--prism{
					&--01{
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:102.01612903225806451612903225806%;
						}
					}
					&--02{
						width: 100%;
						transform: translateY(37.4%);
						&:before{
							content:"";
							display: block;
							padding-top:191.48936170212765957446808510638%;
						}
						video{
							left:0;
							top:1.754385964912280701754385964912%;
						}
					}
					&--03{
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:49.80079681274900398406374501992%;
						}
					}
				}
				&--dcc{
					&--01{
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:62.671232876712328767123287671233%;
						}
					}
					&--02{
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:117.64705882352941176470588235294%;
						}
					}
					&--03{
						transform: translateY(30.1%);
						width: 100%;
						&:before{
							content:"";
							display: block;
							padding-top:175.42503863987635239567233384853%;
						}
						video{
							left:0;
							top:1.754385964912280701754385964912%;
						}
					}
				}
				&--wingos{
					&--01{
						&:before{
							content: "";
						    display: block;
						    padding-top: 85.347985347985347985347985347985%;
						};
					}
					&--02{
					    transform: translateY(-76%);
						&:before{
							content: "";
						    display: block;
						    padding-top: 79.874213836477987421383647798742%;
						}
					}
					&--03{
						&:before{
							content: "";
						    display: block;
						    padding-top: 52.547307132459970887918486171761%;
						}
					}
					&--04{
						&:before{
							content: "";
						    display: block;
						    padding-top: 109.40766550522648083623693379791%;
						}
					}

				}
				&--mazique{
					&--01{
						&:before{
							content: "";
						    display: block;
						    padding-top: 93.962264150943396226415094339623%;
						}
					}
					&--02{
						transform:translateY(3%);
						&:before{
							content: "";
						    display: block;
						    padding-top: 128.53067047075606276747503566334%;
						}
					}

					&--03{
						&:before{
							content: "";
						    display: block;
						    padding-top: 53.411306042884990253411306042885%;
						}

					}
				}
				&--amrelbayoumi{
					&--01{
						&:before{
							content: "";
						    display: block;
							padding-top:40.860215053763440860215053763441%;
						};
					}
					
					&--02{
					    transform: translate(2.4%,26.7%);
						&:before{
							content: "";
						    display: block;
							padding-top:171.47016011644832605531295487627%;
						};
					}
					&--03{
						&:before{
							content: "";
						    display: block;
							padding-top:137.65822784810126582278481012658%;
						}
					}
					&--04{
						&:before{
							content: "";
						    display: block;
							padding-top:87.593984962406015037593984962406%;
						}
					}

				}
				&--ventana{
					&--01{
						&:before{
							content: "";
						    display: block;
							padding-top:42.218246869409660107334525939177%;
						}
					}
					&--02{
						&:before{
							content: "";
						    display: block;
							padding-top:96.451612903225806451612903225806%;
						}
					}
					&--03{
						&:before{
							content: "";
						    display: block;
							padding-top:42.512908777969018932874354561102%;
						}
					}
				}
				&--berkeleydental{
					&--01{
						&:before{
							content: "";
						    display: block;
							padding-top:54.063604240282685512367491166078%;
						}
					}
					&--02{
						transform: translateY(30.9%);
						&:before{
							content: "";
						    display: block;
							padding-top:165.80226904376012965964343598055%;
						}
					}
					&--03{
						&:before{
							content: "";
						    display: block;
							padding-top:129.74910394265232974910394265233%;
						}
					}
				}
			}
			&--vision{
				bottom:0;
			}
			&--aboutus{
				bottom:0;
				right:0;
			}
			&--work-intro{
				right:0;
				bottom:0;
			}
			&--itc{
				&--01{
					width:41vw;
					max-width: 721px;
				    top: 0px;
    				right:11.09375%;
				}
				&--02{
					width:40vw;
				    max-width: 704px;
			        bottom: 0px;
					right: 0px;
				}
				&--03{
					width: 14.5vw;
					max-width: 277px;
					bottom:0;
			        right: 46.40625%;
				}
			}
			&--fc{
				&--01{
					width: 39.3vw;
					max-width: 697px;
			        bottom: 34.3%;
    				right:8.59375%;
				}
				&--02{
					width: 44vw;
					max-width: 850px;
				    bottom:0;
				    left: calc(100% - 9.7%);
				}
				&--03{
					width: 39.3vw;
					max-width: 697px;
		            bottom: 0px;
				    right: 8.59375%;
				}
			}
			&--prism{
				&--01{
					width: 13.5vw;
					max-width:248px;
				    top:0;
			        right: 43.5vw;
					
				}
				&--02{
					width: 39.5vw;
					max-width:757px;
					bottom:0;
					right:0;
					
				}
				&--03{
					width: 26vw;
					max-width:502px;
					bottom:0;
		            right: 42.1vw;
					
				}
			}
			&--dcc{
				&--01{
					width: 15.2vw;
					max-width:293px;
					bottom:0;
				    right: 56.2vw;

				}
				&--02{
					width: 14.2vw;
					max-width:272px;
			        right: 37.8vw;
					bottom:0;

				}
				&--03{
					width: 33.65vw;
					max-width:646px;
					bottom:0;
					right:0;

				}
			}
			&--photography{

				right:0;
				bottom:0;
				width:1249px;
				&:before{
					content:"";
					display: block;
					padding-top:73.178542834267413931144915932746%;
				};
			}
			&--wingos{
				&--01{
					width: 14.21875%;
				    max-width: 273px;
				    top: 0;
			        right: 37.24vw;
				}
				&--02{
					right:0;
					bottom: 0%;
					width:33.125%;
					max-width: 636px;
				}
				&--03{
					bottom:0;
					right:0;
					width:35.78125%;
					max-width: 687px;
				}
				&--04{
					width:14.947916666666666666666666666667vw;
					max-width: 287px;
					bottom:0;
					right: 39.65vw;
				}
			}
			&--mazique{
				&--01{
					width: 13.802083333333333333333333333333vw;
				    max-width: 263px;
				    top: 0;
			        right: 39.5vw;
				}
				&--02{
					max-width: 701px;
					width:36.510416666666666666666666666667vw;
					right: 0;
					bottom:0;
				}
				&--03{
					bottom:0;
					right: 39.5vw;
					width:26.71875vw;
					max-width: 513px;
				}
			}
			&--amrelbayoumi{
				&--01{
					width:24.21875vw;
					top: 0;
			        right: 39.7vw;
			        max-width: 465px;
				}
				&--02{
					width:35.78125vw;
					max-width: 687px;
					bottom:0;
					right:0;
				}
				&--03{
					width:16.458333333333333333333333333333vw;
					max-width: 316px;
					right: 37.05vw;
					bottom:0;
				}
				&--04{
					width:13.854166666666666666666666666667vw;
					max-width: 266px;
				    right: 55.55vw;
					bottom:0;
				}
			}
			&--ventana{
				&--01{
					width:29.114583333333333333333333333333vw;
					max-width: 599px;
					top:0;
					right:36vw;
				}
				&--02{
					max-width:620px;
					width:32.291666666666666666666666666667vw;
					right:0;
				    bottom: 21.7%;

				}
				&--03{
					width:30.260416666666666666666666666667vw;
					max-width: 581px;
					bottom:0;
					right:39.45vw;
				}
			}
			&--berkeleydental{
				&--01{
					width:14.739583333333333333333333333333vw;
					max-width: 283px;
					top:0;
					right: 47.5vw;
				}
				&--02{
					width:40.9375vw;
					max-width: 786px;
					bottom:0;
					right:0;
				}
				&--03{
					width:14.53125vw;
					max-width: 279px;
					bottom:0;
				    right: 43.55vw;
				}
			}
			&--left{
				transform:translate(-50%,0);
			}
			&--left--bottom{
				transform:translate(-50%,50%);
			}
			&--left--top{
				transform:translate(-50%,-50%);
			}
			&--top{
				transform:translate(0%,-50%);
			}
			&--bottom{
				transform:translate(0%,50%);
			}
			&--right{
				transform:translate(50%,0);
			}
			&--right--bottom{
				transform:translate(50%,50%);
			}
			&--right--top{
				transform:translate(-50%,50%);
			}
			&--shown{
				transform:translate(0%,0%);
				opacity: 1;
			}
		}
		&__subtitle{
			font-size: 36px;
			line-height: 43px;
			font-weight: 300;
			margin-bottom: 20px;
		}
		&__list{
			list-style-type:none;
			margin:0;
			padding:0;
			padding-top: 26px;
			margin-bottom: 43px;
			li{
				position: relative;
				margin-bottom: 22px;
				&:last-child{
					margin-bottom: 0;
				}
				&:before{
					position: absolute;
					background-color: #D8D8D8;
					top:13px;
					left:-35px;
					width:6px;
					height:6px;
					content:"";
				};
			}
		}
		&__links{
			display: flex;
			justify-content: space-between;
		}
		&__bg{
			overflow: hidden;
			transform:translate(-50%,-50%)scale(1);
			position: absolute;
			top:50%;
			left:50%;
			width: 100%;
			height:100%;
			video{
				object-fit: cover;
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				min-width: 100%;
				min-height:100%;
			}
			&:after{
				z-index: 1;
				pointer-events: none;
				content:"";
				background:rgba(0,0,0,.6);
				position: absolute;
				top:0%;
				left:0%;
				width: 100%;
				height:100%;
				transform:translateZ(1px);
			};
		}
		&__footer{
			text-transform: uppercase;
			font-size: 14px;
			line-height: 18px;
			padding:20px 0;
			position: absolute;
			bottom:0;
			left:0;
			width: 100%;
			display: flex;
			justify-content: space-between;
			p{
				letter-spacing: 1px;
				margin-top:0px;
				margin-bottom: 8px;
				&:last-child{
					margin-bottom: 0;
				};
			}
		}

	}
	&__team{
		padding:43px 0;
		height:100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&__unit{
			outline:none;
			width:calc((100vw - 220px)/5);
			max-width: 327px;
		    margin-left:28.75px;
		    margin-right:28.75px;
			text-align: center;
			will-change: opacity,transform;
			transition: opacity .5s cubic-bezier(0,0,.2,1),transform .5s cubic-bezier(0,0,.2,1);
			transform:translateX(15%);
			opacity: 0;
			&__image{
				overflow:hidden;
				position: relative;
				background-size: cover;
				background-position: center;
				margin-bottom: 20px;
				box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.25);
				&:before{
					content:"";
					display: block;
					padding-top: 107.03363914373088685015290519878%;
				}
				&:after{
					content:"";
					transform:scaleX(0);
					transform-origin: center left;
					position: absolute;
					bottom:-5px;
					left:0;
					height:5px;
					width: 100%;
					background-color: #fff;
					will-change: bottom,transform;
					transition:bottom .3s linear,transform .1s ease-in-out 1s;
				};
			}
			&__name{
				font-size: 36px;
				line-height: 43px;
				font-weight: 300;
				letter-spacing: -1.14286px;
				will-change: opacity;
				transition:opacity .3s ease-in-out;
			}
			&__position{
				font-size: 21px;
				line-height: 30px;
				color:$grey;
				will-change: opacity;
				transition:opacity .3s ease-in-out .2s;
			}
			&:nth-child(2n-1){
				margin-top: 57px;
			}
			&--side{
				.homepage__team__unit{
					&__name{
						opacity: 0;
					}
					&__position{
						opacity: 0;

					}
				}
			}
			&--shown{
				opacity: 1;
				transform:translateX(0%);
			}
			&--timer{
				.homepage{
					&__team{
						&__unit{
							&__image{
								&:after{
									will-change: transform;
									bottom:0;
									transition:transform 4s ease-in-out;
									transform:scaleX(1);
								}
							}
						}
					}
				}
			}
		}
		&__description{
			margin-bottom: 49px;
			font-size: 16px;
			line-height: 20px;
			text-align: center;
			color:$grey;
		}
		&__info{
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		
		&__controls{
			order:2;
			position: relative;
			z-index: 1;
			margin:0 auto;
			width:calc((100vw - 220px)/5*3 + 115px);
			max-width: 1096px;
			display: flex;
			
			justify-content: space-between;
			&__unit{
				position: relative;
				cursor: pointer;
				width:55px;
				height:28px;
				background-position:center;
				background-size:49px;
				background-image: url("../images/arrow.svg");
				background-repeat: no-repeat;
				&:before{
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-size:49px;
					background-position:center;
					background-image: url("../images/arrow--black.svg");
					background-repeat: no-repeat;
					content:"";
					position: absolute;
					transition: opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
					opacity: 0;
					z-index: 1;
				};
				&:after{
					width:100%;
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-color: #fff;
					content:"";
					position: absolute;
					transform:scale(0);
					transition: transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				};
				&--next{
					transform:rotateY(180deg);
				}
				&:hover{
					&:before{
						opacity: 1;
					};
					&:after{
						transform:scale(1);
					};
				};
			}
		}
		&__wrap{
			display: flex;
			flex-direction: column;
		}
		&__more{
			
			width: 250px;
		}
		.slick{
			&-list{
				order:1;
				padding:0!important;
				position: relative;
				//margin:0 -57.5px;
				&:before,&:after{
					z-index: 1;
					width:calc((100vw - 220px)/5);
					max-width:327px;
					content:"";
					pointer-events: none;
					position: absolute;
					top:0;
					height:100%;
				};
				&:before{
					left:-3px;
					/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0.95+12,0.8+47,0.7+54,0.7+54,0+100 */
					background: -moz-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0.95) 12%, rgba(0,0,0,0.8) 47%, rgba(0,0,0,0.7) 54%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left, rgba(0,0,0,1) 0%,rgba(0,0,0,0.95) 12%,rgba(0,0,0,0.8) 47%,rgba(0,0,0,0.7) 54%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,0.95) 12%,rgba(0,0,0,0.8) 47%,rgba(0,0,0,0.7) 54%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */

				};
				&:after{
					right:-3px;
					/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.7+46,0.7+46,0.8+53,0.95+88,1+100 */
					background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 46%, rgba(0,0,0,0.8) 53%, rgba(0,0,0,0.95) 88%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 46%,rgba(0,0,0,0.8) 53%,rgba(0,0,0,0.95) 88%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.7) 46%,rgba(0,0,0,0.8) 53%,rgba(0,0,0,0.95) 88%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 ); /* IE6-9 */
				}
			}
		}
	}
	&__video{
		text-align: center;
		.homepage__section__list{
			text-align: left;
		}
		&__icon{
			transform:translateX(10px);
			margin:0 auto;
			width:29px;
			height:39px;
			background-image: url("../images/play.svg");
			background-size:cover;
			background-position: center;
		}
	}
	&__result{
		width: 100%;
		text-align: center;
		max-width: 704px;
		margin:0 auto;
	}
}
@media(max-width:1600px){
	.homepage{
		&__section{

			&__pretitle{
				padding-top: 12px;
			    font-size: 18px;
			    line-height: 23px;
			    text-transform: uppercase;
			    position: relative;
			    color: #969696;
			    margin-bottom: 25px;
			    &:before{
		    	    width: 25px;
			    }
			}
			&__comment{
			    font-size: 18px;
			    line-height: 28px;
			    margin-bottom: 26px;
			    a{
			    	&:before{
			    		top: 11px;
					    left: -24px;
					    content: "";
					    width: 6px;
					    height: 6px;
			    	}
			    }
			    &--video{
			    	margin-bottom: 8px;
			    }
			    &--result{
			    	line-height: 27px;
				    max-width: 508px;
				    margin-left: auto;
				    margin-right: auto;
				    margin-bottom: 45px;
			    }
			}
			&__title{
			    font-size: 64px;
			    line-height: 68px;
			    letter-spacing: -2.25px;
			    margin-bottom: 23px;
			    &--team{
		    	    width:calc(100% - 225px);
					max-width: 980px;
			    }
			    &--result{
			    	margin-bottom: 53px;
			    }
			}
			&__subtitle{
			    font-size: 27px;
			    line-height: 32px;
			    margin-bottom: 15px;
			}
			&__text{
				margin-bottom:26px;
				p{
					margin-bottom: 25px;
				}
				&--double{
					margin-bottom: 65px;
				}
				&--video{
					max-width: 512px;
				    margin-bottom: 15px;
				}
			}
			&__info{
				&__divider{
				    margin-top: 0px;
    				margin-bottom: 26px;
				    width: 59px;
				    height: 1px;
				}
				&--about{
					max-width: 489px;
				}
				&--mission{
					max-width: 386px;
				}
				&--vision{
				    max-width: 320px;
				}
				&--work{
					
					max-width: 420px;
					&--itc{
						padding-bottom: 105px;
					}
					&--prism{
						padding-bottom: 68px;
					}
					&--fc{
						padding-bottom: 55px;
					}
					&-dcc{
						padding-bottom: 45px;
					}
					&-intro{
						max-width: 495px;
					}
					&--workpage{
						padding-bottom: 75px;
					}
				}
				&--photography{
					max-width: 488px;
				}
			}
			&__media{
				&__content{
					&--aboutus{
						transform: translateY(15.3%) translateX(7%);
						max-width:576px;
						width:50vw;
					}
					&--vision{
						width:423px;
					}
				}
				&--itc{
					&--01{
					    max-width: 541px;
					}
					&--02{
   						max-width: 528px;
					}
				}
				&--fc{
					&--01{
					    max-width: 522px;
					}
					&--02{					    
						max-width:637px;
					}
					&--03{
						max-width: 522px;
					}
				}
				&--prism{
					&--01{				        
						max-width: 186px;
					}
					&--02{						
						max-width:568px;
					}
					&--03{
						max-width: 376px;
					}
				}
				&--dcc{
				    &--03{
				    	max-width: 484px;
				    }
				    &--02{
			    	    max-width: 204px;
				    }
				    &--01{
				    	max-width:220px;
				    }
				}
			}
			&__cards{
			    padding-top: 19px;
			    max-width: 550px;
			    margin-bottom: -75px;
			    width: calc(100% - 327px - 30px);
				&__unit{
				    margin-bottom: 90px;
				    max-width: 245px;
				    width: calc(50% - 15px);
				    &__title{
			    	    font-size: 22px;
					    line-height: 27px;
					    letter-spacing: -1.2px;
					    margin-bottom: 12px;
				    }
				    &__description{
			    	    font-size: 12px;
    					line-height: 15px;
				    }
				    &__icon{
				    	width: 41px;
					    margin-bottom: 22px;
					    height: 56px;
				    }
				}
			}
			&__steps{
				&__unit{
					&__icon{
					    max-height: 48px;
    					margin-bottom: 23px;
    					
					}
					&__title{
						font-size: 23px;
					    line-height: 27px;
					    margin-bottom: 12px;
					}
					&__text{
						font-size: 12px;
    					line-height: 15px;
					}
					&__wrap{
					    width: 225px;
					}
					&__line{
						&__corner{
							position: absolute;
							
							overflow: hidden;

							&--top{
								width:30px;
								height:30px;

							}
							&--bottom{
								width:23px;
								height:23px;
							}
							
						}
						&--01,&--02,&--04,&--05{
							left:calc(100% - (100% - 225px) + 23px);
							top:31px;
							width:calc((100% - 225px) + 15px);
						}
						&--02,&--04{
							left:calc(100% - (100% - 225px) + 45px);
						}
						/*
						&--04,&--05{
							transform-origin: right center;
							top:29px;
							width:calc((100% - 225px) - 23px);
						}
						&--04{
							left:calc(100% - (100% - 225px) + 64px);
						}
						&--05{
							left:calc(100% - (100% - 225px) + 45px);

						}
						*/
						&--03{
							width:2px;
						    height: 164px;
						    right: 11px;
	    					top: 60px;
	    					&:before{
								height:calc(100% + 60px);
								width:30px;
								bottom:-30px;
							}
							
						}
					}
					&:nth-child(1){
						padding-bottom: 86px;
						.homepage__section__steps__unit__icon img{
							height: 60px;
						}
					}
					&:nth-child(2){
						.homepage__section__steps__unit__icon img{
							height: 56px;
						}
					}
					&:nth-child(3){
						.homepage__section__steps__unit__icon img{
							height: 65px;
						}
					}
					&:nth-child(4){
						.homepage__section__steps__unit__icon img{
							height: 90px;
						}
					}
					&:nth-child(5){
						.homepage__section__steps__unit__icon img{
							height: 75px;
						}
					}
					&:nth-child(6){
						.homepage__section__steps__unit__icon img{
							height: 64px;
						}
					}
				}
			}
			&__list{
				padding-top: 19px;
				margin-bottom: 31px;
				li{
				    margin-bottom: 16px;

				    &:before{
				    	top: 9px;
					    left: -23px;
					    width: 4px;
					    height: 4px;
				    }
				}
			}
			&__footer{
				font-size: 12px;
    			line-height: 16px;
    			letter-spacing: .75px;
			}
		}
		&__team{
			padding:31px 0;
			&__unit{
			    margin-left: 21px;
				margin-right: 21px;
				&:nth-child(2n-1) {
   					margin-top: 43px;
				}
				&__image{
					margin-bottom: 15px;
				}
				&__name{
				    font-size: 27px;
				    line-height: 32px;
				    font-weight: 300;
				    letter-spacing: -0.824286px;
				}
				&__position{
					font-size: 16px;
    				line-height: 23px;
				}
			}
			&__description{
				margin-bottom: 37px;
			    font-size:12px;
			    line-height: 15px;
			}
			&__more{
			    width: 186px;
			}
		}
		&__video{
			&__icon{
				transform: translateX(7px);
				width:22px;
				height:29px;
			}
		}
		&__result{
			max-width: 528px;
		}
	}
}
@media(max-width:1180px){
	.homepage__section__media--itc--01 {
		right: 6%;
	}
}
@media(max-width:1023px){
	.homepage{
		&__section{
			&__mission{
				width: 29.3vw;
			}
			&__pretitle{
				padding-top: 2px;
				font-size: 14px;
				line-height: 30px;
				margin-bottom: 13px;
				&:before{
				    width: 21px;
				};
			}
			&__subtitle{
				font-size: 30px;
				line-height: 36px;
			}
			&__title{
				font-size: 48px;
				line-height: 58px;
				letter-spacing: -3.03571px;
				margin-bottom: 28px;
				&--team{
					width:100%;
				}
			}
			&__info{
				
				&--work{
					max-width: 360px;
					&--fc{
					    padding-bottom: 80px;
					}
					&--workpage{
						padding-bottom: 50px;
					}
				}
				&--about{
				    max-width: 485px;
				}
				&__divider{
					margin-top: 22px;
    				margin-bottom: 26px;
				}
				&--vision{
					width:50%;
				}
			}
			&__text{
				p{
				    margin-bottom: 15px;
				}
				&--double{
					margin-bottom: 40px;
				}
			}
			&__media{
				&__content{
					&--aboutus{
						width: 59vw;
					}
					&--vision{
						transform: translateX(-28%) translateY(-1.33%);
					}
					&--work{
						&-intro{
							width: 79.458333vw;
						    transform: translate(16%,.3%);
						}
					}
					&--itc{
						&--03{
							transform:translateY(30%);
						}
					}
					&--fc{
						&--01{
							transform: translateX(65%);
						}
						&--03{
						    transform: translate(0,71%);
						}
					}
					&--prism{
						&--01{
							transform: translateY(-43%);
						}
						&--02{
							transform: translate(47.5%,8.6%);
							video{
								display: none;
							}
						}
						&--03{
							transform:translateY(6%);

						}

					}
					&--dcc{
						&--02{
							transform: translateY(12%);
						}
						&--03{
							transform: translate(56%,2%);
							video{
								display: none;
							}
						}
					}
					&--wingos{
						&--01{
							transform: translateY(-13.5%);
						}
						&--02{
						    transform: translate(46%,-86%);
						}
						&--03{
						    transform: translate(5%,0%);
						}
						&--04{
							transform: translate(0, 19%);
						}
					}
					&--mazique{
						&--01{
						    transform: translateY(-31%);
						}
						&--02{
							transform: translate(57.5%,0%);
						}
						&--03{
						    transform: translateY(10%);
						}
					}
					&--amrelbayoumi{
						&--02{
							transform: translate(47.9%,5.7%);
						}
						&--03{
							transform: translateY(10.6%);
						}
						&--04{
							transform: translateY(20%);
						}
					}
					&--ventana{
						&--02{
							transform: translate(35%,50%);
						}

					}
					&--berkeleydental{
						&--02{
							transform: translate(55%,0%);
						}
						&--03{
							transform:translateY(49%);
						}
					}

				}
				&--vision{
					width:47.265625vw;
				}
				&--itc{
					&--01{
						max-width: 420px;
					    width: 44vw;
					    right: 20.2%;

					} 
					&--03{
						transform-origin: bottom right;
						transform:rotate(-90deg);
						max-width: 160px;
					    width: 20.1833vw;
					    bottom:67.5%;
    					right: 0;
					}
					&--02{
						max-width: 420px;
						width: 50vw;
					}
				}
				&--fc{
					&--01{

						width: 77vw;
					    bottom: 21.5%;
    					right:0;
    					max-width: 600px;
					}
					&--03{
					    right: 24%;
						width: 59.5vw;
						max-width: 600px;
					}
					&--02{
						display: none;
					}
				}
				&--prism{
					&--01{
						max-width: 220px;
						width:27vw;
						right: 34.5vw;
					}
					&--02{
						width:52.5vw;
						max-width: 410px;
					}
					&--03{
					    width: 54.8vw;
    					max-width: 430px;
					    right: 35.5vw;
					}
				}
				&--dcc{
					&--01{
					    width: 26.433vw;
				        right: 61.1vw;
					}
					&--03{
				   		width: 58.6vw;
				   	}
				   	&--02{
				   		width: 29.2vw;
					    max-width: 230px;
					    right: 29vw;
				   	}
				}
				&--wingos{
					&--01{
						max-width: 213px;
						width: 27.74%;
					    right: 34.5vw;
					}
					&--02{
						max-width:467px;
						width: 58.125%;
					}
					&--03{
						width: 58.78125%;
						max-width: 452px;
					}
					&--04{
						width: 23.947917vw;
					    max-width: 184px;
					    right: 61.8vw;
					}
				}
				&--mazique{
					&--01{
					    width: 27.4vw;
					    max-width: 211px;
					    top: 0;
					    right: 36.3vw;
					}
					&--02{
						max-width: 580px;
						width: 75.510417vw;
					}
					&--03{
						right: 39.9vw;
					    width: 45.2vw;
					    max-width: 347px;
					}
				}
				&--amrelbayoumi{
					&--01{
						right: 21.7vw;
						max-width: 369px;
					    width: 48vw;
					}
					&--02{
						width: 56.78125vw;
    					max-width: 436px;
					}
					&--03{
						width: 22.9vw;
					    max-width: 176px;
					    right: 33.5vw;
					}
					&--04{
					    width: 19.4vw;
					    max-width: 150px;
					    right: 59.2vw;
					}
				}
				&--ventana{
					&--01{
						width: 48.114583vw;
					    max-width: 370px;

					    right: 21.5vw;
					}
					&--02{
						max-width: 327px;
					    width: 42.6vw;
					    right: 0;
					    bottom: 52%;
					}
					&--03{
					    width: 52.260417vw;
					    max-width: 402px;
					    bottom: 0;
					    right: 26.45vw;
					}
				}
				&--berkeleydental{
					&--01{
						width: 30.5vw;
					    max-width: 235px;
					    right: 35vw;
					}
					&--02{
						width: 56.3vw;
					    max-width: 433px;
					    bottom: 0;
					    right: 0;
					}
					&--03{
					    width: 30.53125vw;
					    max-width: 235px;
					    bottom: 0;
					    right: 35.1vw;
					}
				}
			}
			&__horizontal{
				&--vision{
					margin-bottom: 17vh;
					flex-direction: column;
				}
			}
			&__cards{
				width:50%;
				margin-left: auto;
				padding-top: 0;
				padding-bottom: 29px;
				z-index: 1;
				&__unit{
					outline:none;
					max-width: 100%;
					margin-bottom: 0;
					&__icon{
					    width: 55px;
					    margin-bottom: 30px;
					    height: 75px;
					}
					&__title{
						font-size: 29px;
						line-height: 36px;
						letter-spacing: -1.5px;
						margin-bottom: 18px;
					}
					&__description{
						font-size: 14px;
						line-height: 20px;
					}
				}
				
			}
			&__steps{
				&__unit{
					padding:0 15px;
					&__title{
						font-size: 24px;
						line-height: 29px;
						margin-bottom: 7px;
					}
					&__text{
						font-size: 14px;
						line-height: 20px;
					}
					
					&__wrap{
						width: 	100%;
						max-width: 190px;
					}
					&__line{
						&--03{
							right: -34px;
    						top: 61px;
						    height: 161px;

						}
						&--01,&--02,&--04,&--05{
							left:calc(100% - 25px);
							width:calc(100vw/3 - 100% - 10px);
						}
					}
					&:nth-child(1){
					    padding-bottom: 35px;
					}
					&:nth-child(1),&:nth-child(6){
					    padding-left:0;
					}
					&:nth-child(3),&:nth-child(4){
						padding-left: 30px;
						padding-right: 0;

					}
				}
			}
			&__wrap{
				&--alignTop{
				    padding-top: 11vh;
				}
			}
			&__list{
			    padding-left: 23px;
			}
			&__comment{

				&--video{
					font-size: 16px;
					line-height: 20px;
					margin-bottom: 10px;
				}
				&--result{
					max-width: 440px;
					font-size: 16px;
					line-height: 25px;
				}
			}
			&__footer{
				padding:15px 0;
				font-size: 14px;
				line-height: 16px;
			}
		}
		&__team{
			justify-content: center;
			&__wrap{
				width:calc(100% + 60px);
				margin-left: -30px;
			}
			&__controls{
				width:calc(100% - 20px);
			}
			&__unit{
				margin-left: 15px;
				margin-right: 15px;
				&__image{
					margin-bottom: 22px;
				    max-height: 33vh;
					&:before{
						padding-top: 153.477273%;
					};
				}
				&__child{
					&:nth-child(2n-1){
						margin-top: 30px;
					}
				}
				&__name{
					font-size: 24px;
					line-height: 29px;
					letter-spacing: -1.14286px;
				}
				&__position{
					font-size: 18px;
					line-height: 30px;
				}
				&--side{
					.homepage__team__unit__name{
						transition-delay: 0s;
						opacity: 1;
					}
					.homepage__team__unit__position{
						transition-delay: 0s;
						opacity: 1;
					}
				}
			}
			&__description{
				margin-bottom: 28px;
				width: calc(100% - 105px);
				font-size: 16px;
				line-height: 25px;
				br{
					display: none;
				}
			}
			&__more{
				width: 236px;
			}
		}
		&__video{
			max-width: 430px;
			text-align: left;
			&__icon{
			    margin-left: 112px;
			}
		}
		&__result{
			max-width: 500px;
		}
	}
}
@media(max-width:760px){
	.homepage{
		&__section{
			&__pretitle{
				font-size: 10px;
				line-height: 30px;
				margin-bottom: 7px;
				&:before{
					width:15px;
				};
			}
			&__comment{
				font-size: 16px;
				line-height: 25px;
				margin-bottom: 14px;
				a{
				    padding-left: 29px;
				    &:before{
				    	top: 8px;
    					left: 4px;
				    }
				}
				&--result{
					font-size: 14px;
    				line-height: 25px;
    				margin-bottom: 39px;
				}
			}
			&__title{
				position: relative;
				letter-spacing: 0;
				font-size: 24px;
				line-height: 29px;
				margin-bottom: 20px;
				&--team{
					padding:0;
					margin-bottom: 30px;
				}
				&__main{
					transition: opacity .3s ease-in-out 0s;
				}
				&__alt{
					opacity: 0;
					display: inline;
					position: absolute;
					top:0;
					left:0;
					transition: opacity .3s ease-in-out 0s;
				}
				&--alt{
					.homepage{
						&__section{
							&__title{
								&__alt{
									transition: opacity .3s ease-in-out 0s;
									opacity: 1;
								}
								&__main{
									transition: opacity .3s ease-in-out 0s;
									opacity: 0;
								}
							}
						}
					}
				}
			}
			&__subtitle{
				margin-bottom: 19px;
				font-size: 18px;
				line-height: 22px;
			}
			&__text{
				&__unit{
					width: 100%;
				}
				&--double{
					.homepage__section__text__unit{
						&:nth-child(2){
							display: none;
						}
					}
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
			&__mission{
			    margin-top: 20px;
				width: 100%;
				display: none;
			}
			&__wrap{
				min-height: 90vh;
				//justify-content: flex-start;
				padding-top: 40px;
				padding-bottom: 40px;
				&--aboutus{
					padding-bottom: 70vw;
				}
				&--vision{
					padding-bottom: 60vw;
				}
				&--itc{
				    padding-bottom: 36vw;
				}
				&--fc{
					padding-bottom: 84vw;
				}
				&--process{
					//justify-content: center;
				}
				&--prism{
					padding-bottom: 35vw;
				}
				&--dcc{
					padding-bottom: 44vw;
				}
				&--alignTop{
					justify-content: center;
				}
				&--photography{
				    padding-bottom: 26vw;
				}
				&--result{
					padding-bottom: 140px;
				}
				&--work-intro{
					padding-bottom: 26vw;
					justify-content: center;
				}
				&--wingos{
					padding-bottom: 45vw;
				}
				&--mazique{
					padding-bottom: 30vw;
				}
				&--amrelbayoumi{
				    padding-bottom: 38vw;
				}
				&--ventana{
				    padding-bottom: 17vw;
				}
				&--berkeleydental{
					padding-bottom: 55vw;
				}
			}
			&__info{
				
				&--about{
					max-width: 300px;
					
				}
				&__divider{
					display: none;
				}
				&--vision{
					display: none;
					width:100%;
					
				}
				&--work{
					max-width: 400px;
					&--itc{
						padding-bottom: 0;
					}
					&--prism{
						padding-bottom: 0;
					}
					&--dcc{
						max-width: 400px;
					}
					&-intro{
						position: static;
						flex:inherit;
					}
					&--workpage{
						padding-bottom: 0;
					}
				}
			}
			&__cards{
				margin-bottom: 0;
				max-width: 300px;
				width:100%;
				&__unit{
					position: relative;
					&__icon{
						position: absolute;
						width: 48px;
					    margin-bottom: 0px;
					    height: 62px;
					}
					&__title{
						margin-top: 34px;
						font-size: 18px;
						line-height: 22px;
						letter-spacing: -1.5px;
						padding-left: 68px;
						margin-bottom: 29px;
					}
				}
				.slick-next{
					display:none!important;
				}
			}
			&__media{
				
				&__content{
					&--aboutus{
						transform: translateY(15.3%) translateX(16%);
						width: 93.666667vw;
					}
					&--vision{
						position: relative;
						left:50%;
						width: 100%;
						max-width: 100%;
					    transform: translateX(-50%) translateY(-5.33%);
					    bottom:0;
					}
					&--itc{
						&--02{
							transform: translate(50px,26%);
						}
					}
					&--fc{
						&--01,&--03{
							transform: translate(50px,0%);
						}
						&--03{
						    transform: translate(50px,28%);
						}
					}
					&--prism{
						&--03{
							transform: translate(calc( 50px - 8%),0);
						}
					}
					&--dcc{
						&--02{
							transform: translate(10%,18%);
						}
					}
					&--work-intro{
					    width: 85.458333vw;
					    transform:translate(-23%,0);
					}
					&--amrelbayoumi{
						&--03{
							transform: translateY(34.6%);
						}
					}
					&--berkeleydental{
						&--03{
							transform:none;
						}
					}


				}
				&--vision{
					width:65.933333333333333333333333333333vw;
					left:0;
				}
				&--itc{
					&--01{
						display: none;
					}
					&--03{
						display: none;
					}
					&--02{
						right:inherit;
						left:0;
						max-width: 71.733333vw;
					    width: 71.733333vw;

					}
				}
				&--fc{
					&--01{
						bottom: 34.5%;
					}
					&--01,&--03{
						right:inherit;
						left:0;
						max-width: 71.733333vw;
					    width: 71.733333vw;
					}
					&--01{
						bottom: 48vw;
					}
				}
				&--prism{
					&--01{
						display: none;
					}
					&--02{

						display: none;
					}
					&--03{
						right:inherit;
						left:0;
						max-width: 78.933333333333333333333333333333vw;
					    width: 78.933333333333333333333333333333vw;
					}
				}
				&--dcc{
					&--01{
					    right: 43.4vw;
						width:39.466666666666666666666666666667%;
						max-width:39.466666666666666666666666666667%;
					}
					&--02{
						width:43.2vw;
						max-width:43.2vw;
						right:0;
					}
					&--03{
						display: none;
					}
				}
				&--wingos{
					&--01{
						display: none;
					}
					&--02{
						display: none;
					}
					&--03{
						width:92%;
					}
					&--04{
						display: none;
					}
				}
				&--mazique{
					&--01{
						display: none;
					}
					&--02{
						display: none;
					}
					&--03{
						right: 6.9vw;
					    width: 71.2vw;
					    max-width: 267px;
					}
				}
				&--amrelbayoumi{
					&--01{
						display: none;
					}
					&--02{
						display: none;
					}
					&--03{
						width: 38.9vw;
					    max-width: 150px;
					    right: -2vw;
					}
					&--04{
						width: 34.4vw;
					    max-width: 130px;
					    right: 41.2vw;
					}
				}
				&--ventana{
					&--01{
						display: none;
					}
					&--02{
						display: none;
					}
					&--03{
						width: 63.260417vw;
					    max-width: 238px;
					    bottom: 0;
					    right: 11.45vw;
					}
				}
				&--berkeleydental{
					&--01{
						display: none;
					}
					&--02{
						display: none;
					}
					&--03{
					    width: 40.53125vw;
					    max-width: 152px;
					    bottom: 0;
					    right: 28vw;
					}
				}
				&--work{
					&-intro{
						left:0;
						right:inherit;
					}
				}
			}
			&__steps{
				padding-top: 30px;
				padding-bottom: 14px;
				width: calc(100% + 120px);
    			margin-left: -60px;
				&__unit{
					padding:0 60px!important;
					&:nth-child(1){
						padding-bottom: 0;
					}
					&__wrap{
						transition-delay: 0s!important;
						margin:0 auto;
					}
					&__icon{
						margin-bottom: 31px;
						img{
						    margin: 0 auto;
						}
					}
					&__title{
						margin-bottom: 9px;
						font-size: 18px;
						line-height: 22px;
					}
					&__line{
						transition-delay: 0s!important;
						&--01,&--02,&--03,&--04,&--05{
							
							width: calc(100vw - 124px);
						}
						&--02,&--03{
						    width: calc(100vw - 139px);
						}
						&--01,&--02,&--03{
							left: calc(50vw + 62px);
						}	
						&--04,&--05{

							right: calc(50vw + 62px);
							left:inherit;
						}
						&--03{
							width: calc(100vw - 157px);
							left: calc(50vw + 88px);
							top:31px;
							height:2px;
						}
						&--04{
							width: calc(100vw - 135px);
						}
						&__corner{
							display: none;
						}
					}
				}
				.slick-prev{
					left:50px;
					bottom:0;
				}
				.slick-next{
					left:calc(100% - 98px);
				    right: 61px;
					bottom: 0px;
				}
			}
			&__list{
				line-height: 22px;
				padding-left: 35px;
			    padding-top: 6px;
				margin-bottom: 36px;
				li{
				    margin-bottom: 5px;
				    &:before{
				    	top: 9px;
					    left: -35px;
					    width: 6px;
					    height: 6px;
				    };
				}
			}
			&__links{
				text-align: center;
				display:flex;
				flex-direction: column;
				align-items: center;
				&__unit{
					margin:0 auto;
					width: 208px;
					margin-bottom: 30px;
					span{
						padding:0;
					}
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			&__footer{
				text-align: center;
				flex-direction: column;
				align-items: center;
				font-size: 9px;
				line-height: 12px;
				p{
					margin-bottom: 5px;
				}

			}
			&__container{
				height: 100%;
			}
			&__horizontal{
				&--vision{
					margin-bottom: 0;
				}
			}

		}
		&__copyright{
			margin-bottom: 10px;
		}
		&__team{
			padding:0px;
			&__wrap{
				width: calc(100% + 60px);
    			margin-left:-30px;
    			margin-bottom: 20px;
			}
			&__unit{
				width:45vw;
				margin-left: 10px;
				margin-right:10px;
				&:nth-child(2n-1){
					margin-top: 0;
				}
				&__image{
					margin-bottom: 27px;
					max-height: inherit;
					&:before{
						padding-top:142.23300970873786407766990291262%;
					}
				}
				&__name{
					font-size: 18px;
					line-height: 22px;
					letter-spacing: -1.14286px;
				}
				&__position{
					font-size: 16px;
					line-height: 30px;
				}
			}
			&__description{
				width: 100%;
				text-align: left;
				font-size: 14px;
				line-height: 25px;
				&--hide{
					display: none;
				}
			}
			&__controls{
				display: none;
			}
			&__more{
				width:228px;
			}

			&__info{
				align-items: flex-start;
			}
			.slick-list{
				&:before{
					width: 63px;

				}
				&:after{
				    width: 63px;
				}
			}
		}
		&__photography{
			&__more{
			    margin-left: 20px;
			}
		}
		&__video{
			&__icon{
				margin-left: 52px;
			    width: 16px;
				height: 22px;
			}
			&__more{
				margin:0 auto;
			}
		}
		&__result{

			text-align: left;
		}
		&__address{
			display: none;
		}
		&:before{
			position: fixed;
			top:0;
			left:0;
			bottom:0;
			right:0;
			background-color: #fff;
			z-index: 100;
			color:#fff;
			content:"Rotate your device";
		};
	}
}