.mobile-slider{
	opacity: 0;
	transition: opacity .3s linear;
	position: relative;
	&--shown{
		opacity: 1;
	}
	.slick{
		&-arrow{
			transition: opacity .3s ease-in-out;
			display: block;
			cursor: pointer;
		    left: calc(100% + 18px);
		    bottom: 36%;
		    width: 78px;
		    height: 26px;
			position: absolute;
			font-size: 0px;
			line-height: 0px;
			outline:none;
			border:none;
			background-color: transparent;
			background-position:center 40%;
			background-size:80%;
			background-image: url("../images/arrow.svg");
			background-repeat: no-repeat;
			transform:rotateY(180deg);
			&:before{
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-size:80%;
				background-position:center 40%;
				background-image: url("../images/arrow--black.svg");
				background-repeat: no-repeat;
				content:"";
				position: absolute;
				transition: opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				opacity: 0;
				z-index: 1;
			};
			&:after{
				width:100%;
				top:0;
				left:0;
				width:100%;
				height:100%;
				background-color: #fff;
				content:"";
				position: absolute;
				transform:scale(0);
				transition: transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
			}
			
			&:hover{
				&:before{
					opacity: 1;
				};
				&:after{
					transform:scale(1);
				};
			};
			&.slick-prev{
				    transform: scale(1,1);
			}
			&.slick-disabled{
				opacity: 0;
			}
		}

		&-dots{
			position: absolute;
			bottom:0;
			left:0;
			margin:0;
			padding:0;
			display: flex;
			button{
				display: none;
			}
			list-style-type: none;
			li{
				width:40px;
				height:1px;
				margin-right: 10px;
				background-color:$grey-2;
				position: relative;
				&:before{
					will-change: transform;
					content:"";
					position: absolute;
					width:100%;
					height:100%;
					background-color: #fff;
					opacity: 1;
					transform:scaleX(0);
					transform-origin: center left;
				};
				
				&.slick-active{
					&:before{
						transition: transform 8.3s ease-in-out;
						transform:scaleX(1);
					}
				}
			}
		}
	}
}
@media(max-width:1600px){
	.mobile-slider{
		.slick{
			&-dots{
				li{
					width: 30px;
				}
			}
			&-arrow{
				cursor: pointer;
			    left: calc(100% + 44px);
			    bottom: 34%;
			    width: 48px;
			    height: 26px;
				position: absolute;
				font-size: 0px;
				line-height: 0px;
				outline:none;
				border:none;
				background-color: transparent;
				background-position:center;
				background-size:42px;
				background-image: url("../images/arrow.svg");
				background-repeat: no-repeat;
				transform:rotateY(180deg);
				&:before{
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-size:42px;
					background-position:center;
					background-image: url("../images/arrow--black.svg");
					background-repeat: no-repeat;
					content:"";
					position: absolute;
					transition: opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
					opacity: 0;
					z-index: 1;
				};
				&:after{
					width:100%;
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-color: #fff;
					content:"";
					position: absolute;
					transform:scale(0);
					transition: transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				}
				
				&:hover{
					&:before{
						opacity: 1;
					};
					&:after{
						transform:scale(1);
					};
				};
			}
		}
		
	}
}
@media(max-width:760px){
	.mobile-slider{
		.slick{
			&-arrow{
				&:before,&:after{
					display: none;
				}
			}
			&-dots{
				li{
					width: 20px;
				}
			}
		}
	}
}