.footer{
	position: fixed;
	bottom:0;
	left:0;
	width: 100%;
	color:#fff;
	pointer-events: none;
	background-color:#1A1919;
	background-image: url(../images/footer-bg.png);
	&__body{
		height:100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	&__wrap{
		pointer-events: all;
		padding-top: 44px;
		padding-bottom: 41px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__logo{
		width:95px;
		margin-bottom:32px;
		img{
			width: 100%;
		}
	}
	&__menu{
		display: flex;
		margin-bottom: 40px;
		&__unit{
		    margin-right: 90px;
			&:last-child{
				margin-right: 0;
			}
			a{
				position: relative;
				display: inline-block;
				&:before,&:after{
					position: absolute;
					content:"";
					width:0;
					bottom:0;
					left:0;
					height:1px;
					background-color: #fff;
				    transition: .3s cubic-bezier(.5,0,.2,1);
				}
				&:before{
					opacity: .4;
				}
				&:hover{
					&:before,&:after{
						width:100%;
					}
					&:after{
						transition-delay: .15s;
					};
				};
			}
		}
	}
	&__socials{
		display: flex;
		margin-bottom: 40px;
		&__unit{
			width:24px;
			height:24px;
			margin-right: 58px;
			
			background-position: center;
			background-repeat: no-repeat;
			background-position: center;
			&:last-child{
				margin-right: 0;
			}
			&--insta{
				background-size: 40px;
				background-image:url("../images/instagram.svg");
			}
			&--twitter{
			    background-size: 33px;
				background-image:url("../images/twitter.svg");
			}
			&--fb{
			    background-size: 40px;
				background-image:url("../images/facebook.svg");
			}
		}
	}
}
@media(max-width:1600px){
	.footer{
		&__wrap{
			padding-top: 32px;
	    	padding-bottom: 31px;
	    }
	    &__logo{
	    	width:71px;
	    	margin-bottom: 24px;
	    }
	    &__menu{
	    	margin-bottom: 30px;
	    	&__unit{
	    		margin-right:67px;
	    	}
	    }
	    &__socials{
	    	margin-bottom: 30px;
	    	&__unit{
	    		width:18px;
	    		height:18px;
	    		margin-right: 45px;
	    	}
	    }
	}
}
@media(max-width:1023px){
	.footer{
		&__wrap{
			padding-bottom: 28px;
			padding-top:44px;
		}
		&__logo{
			margin-bottom: 43px;
		    width: 95px;
		}
		&__menu{
			font-size: 14px;
			line-height: 40px;
			margin-bottom: 42px;
			&__unit{
			    margin-right: 47px;
			}
		}
		&__socials{
		    margin-bottom: 42px;
			.socials{
				&__unit{
					width: 16px;
					height: 16px;
					margin-right: 40px;
					&:last-child{
						margin-right: 0;
					}
					&__circle{
						width: 40px;
						height:40px;
					}
					&--fb{
						width:9px;
					}
					&--twitter{
						width:19px;
					}
				}
			}
		}
		&__copyright{
			font-size: 14px;
			line-height: 30px;
		}
	}
}
@media(max-width:1023px){
	.footer{
		&__wrap{
			padding-bottom: 45px;
			padding-top:40px;
		}
		&__logo{
			width:75px;
		    margin-bottom: 28px;
		}
		&__menu{
			margin:0 auto;
			flex-wrap:wrap;
			justify-content: center;
			width:320px;
			line-height: 30px;
			    margin-bottom: 23px;
			&__unit{
				transform: translateX(2px);
				margin: 0 24px;
			    margin-bottom: 21px;
				&:last-child{
					margin:0 20px;
				    margin-bottom: 21px;
				}
				&:nth-child(n+4){
				    transform: translateX(-4px);
				    margin: 0px 11px;
				    margin-bottom: 21px;
				}
			}
		}
	}
}