.page{
	position: relative;
	z-index: 1;
	background-color: #000;
	margin-bottom: 329px;
	&__contacts{
	    padding-top: 223px;
		padding-bottom: 168px;
	}
	&__media{
		will-change: opacity;
		transition: opacity 1s ease-in-out .5s;
		opacity: 0;
		&--shown{
			opacity: 1;
		}
	}
}
.team{
	overflow:hidden;
	color:#fff;
	&__title{
		font-size: 85px;
		line-height: 102px;
		letter-spacing: -3.03571px;
		font-weight: 300;
		margin-bottom: 43px;
	}
	&__header{
		padding-top: 180px;
		margin-bottom: 64px;
		&__text{
			max-width: 671px;
		}
	}
	&__subtitle{
		margin-bottom: 42px;
		font-weight: 300;
		font-size: 85px;
		line-height: 102px;
		letter-spacing: -3.03571px;
	}
	&__video{
		max-height: 100vh;
		position: relative;
		overflow:hidden;
		&:before{
			will-change: padding;
			transition: padding .5s linear;
			content:"";
			display: block;
			padding-top:39.84375%;
		};
		video{
			width: 100%;
			border-radius: 1px;
			object-fit: cover;
			min-width: 100%;
			min-height:100%;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			position: absolute;
		}
		&__button{

			cursor: pointer;
			position: absolute;
			top:50%;
			left:50%;
			width:110px;
			height:110px;
			transform:translate(-50%,-50%);
			will-change: opacity,visibility;
			transition:opacity .3s ease-in-out,visibility .3s ease-in-out;

			svg{
				width: 100%;
				circle{
					will-change: transform;
					transform-origin: center;
					transition:transform .3s cubic-bezier(0.39, 0.575, 0.565, 1);
				}
			}
			&:hover{
				svg{
					circle{
						transform:scale(.8);
					}
				}
			};
		}
		&__close{
			opacity: 0;
			visibility: hidden;
			cursor: pointer;
			top: 20px;
    		right: 20px;
			position: absolute;
			width:20px;
			height:20px;
			background-image: url("../images/close.svg");
			background-size: cover;
			background-position: center;
			will-change: opacity,visibility,transform;
			transition:transform .5s cubic-bezier(0.23, 1, 0.32, 1), opacity .5s cubic-bezier(0.23, 1, 0.32, 1), visibility .5s cubic-bezier(0.23, 1, 0.32, 1);
			&:hover{
				transform:scale(.8);
			};
		}
		&:after{
			z-index: 1;
			content:"";
			will-change: opacity,visibility;
			transition:opacity 1s ease-in-out,visibility 1s ease-in-out;
			visibility: visible;
			position: absolute;
			background-color:#000;
			top:0;
			left:0;
			width: 100%;
			height:100%;
		}
		&--shown{
			&:after{
				opacity: 0;
				visibility: hidden;
			};
		}
		&--expanded{
			&:before{
				padding-top: 200%!important;
			};
			.team__video{
				&__close{
					opacity: 1;
					visibility: visible;
				}
				&__button{
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}

	&__slider{
		padding-top: 8.854166666666666666666666666667%;
		overflow:hidden;
		margin:0 5px;
		
		&__images{
			.slick-list{
				overflow:visible;
			}
			&__unit{
				position: relative;
				height::8.333333333333333333333333333333vw;
				max-height: 160px;
				will-change: transform, opacity;
				transition:transform .5s ease-in-out, opacity .5s ease-in-out;
				opacity: 0;
				transform:translateX(20%);
				&__wrap{
					will-change: bottom,transform;
					transition: bottom .7s ease-in,transform .7s ease-in;
					overflow:hidden;
					position: absolute;
					width: 86.542419%;
					left:3.968253968253968253968253968254%;
					bottom:-1px;
					transform-origin: bottom left;
					&:before{
						content:"";
						display: block;
						padding-top:48.929663608562691131498470948012%;
						will-change: padding;
						transition:padding .7s ease-in-out;
					};
				}
				&__inner{
					width:100%;
					background-size: cover;
					background-position: center;
					position: absolute;
					bottom:50%;
					left:0;
					transform:translate(0,50%);
					will-change: bottom, transform;
					transition: bottom .7s ease-in,transform .7s ease-in;
					
					&:before{
						display: block;
						content:"";
						padding-top:122.70642201834862385321100917431%;
					}
					&:after{
						will-change:opacity;
						transition: opacity .7s ease-in-out;
						content:"";
						position: absolute;
						top:0;
						left:0;
						right:0;
						bottom:0;
						background:linear-gradient(0deg, rgba(26, 28, 31, 0.6), rgba(26, 28, 31, 0.6));
					};
				}
				&--01{
					.team__slider__images__unit__inner{
					    transform: translate(0,64%);
					}
				}
				&--02{
					.team__slider__images__unit__inner{
					    transform: translate(0,45%);
					}
				}
				&--03{
					.team__slider__images__unit__inner{
						transform: translate(0,76%);
					}
				}
				&--04{
					.team__slider__images__unit__inner{
						transform: translate(0,77.6%);
					}
				}
				&--05{
					.team__slider__images__unit__inner{
						transform: translate(0,57%);
					}
				}
				&--highlighted{
					z-index:1;
					.team__slider__images__unit__wrap{
					    transform: translate(-6.4%,0) scale(1.314);
						
						&:before{
							padding-top:122.70642201834862385321100917431%;
						};
					}
					.team__slider__images__unit__inner{
					    transform: translate(0,50%);
					    &:after{
					    	opacity: 0;
					    }
					}
					
				}
				&--moved{
					.team__slider__images__unit__wrap{
				    	transform: translate(21px,0%);
					}
					&.team__slider__images__unit--highlighted--next{
						.team__slider__images__unit__wrap{
							transform: translate(19.2%,0%);
						}

					}
					&.team__slider__images__unit--highlighted--next--next{
						.team__slider__images__unit__wrap{
							transform: translate(12.8%,0%);
						}

					}
				}
				&--shown{
					opacity: 1;
					transform:translateX(0%);
				}
			}
		}
		&__info{
		    padding-bottom: 3.9vw;
			padding-left:22.5%;
			width:calc(100%/5*3);
			margin:0 auto;
			
			&__unit{
				padding-left: 5.572916666666666666666666666667vw;
				will-change: opacity,transform;
				transition:transform .5s ease-in-out, opacity .5s ease-in-out;
				opacity: 0;
				transform:translateX(20%);
				&--shown{
					opacity: 1;
					transform:none;
				}
			}
			&__position{
				color: #7E7E7E;
				font-family: Aeonik;
				font-size: 1.25vw;
				line-height: 1.5625vw;
				margin-bottom:2.1875vw;
			}
			&__name{
				font-size:2.5vw;
				line-height: 3.020833333333333333333333333333vw;
				font-weight:300;
				letter-spacing:-0.059523958333333333333333333333333vw;
				margin-bottom: 0.885416666666666666666666666667vw;
			}
			&__description{
				max-width: 77%;
				font-size: 1.25vw;
				line-height:1.510416666666666666666666666667vw;
				letter-spacing:-0.059523958333333333333333333333333vw;
				margin-bottom:1.979166666666666666666666666667vw;
				color: rgba(255, 255, 255, 0.65);
			}
		}
		&__controls{
			margin-top:0;
			padding-left: 5.572916666666666666666666666667vw;
			position: relative;
			z-index: 1;
			width: 40%;
			max-width: 1096px;
			display: flex;
			justify-content: space-between;
			will-change: opacity,transform;
			transition:transform .5s ease-in-out, opacity .5s ease-in-out;
			opacity: 0;
			transform:translateX(20%);
			&--shown{
				opacity: 1;
				transform:none;
			}
			&__unit{
				cursor: pointer;
				width:3.1901vw;
				height:1.471875vw;
				background-position:center 40%;
				background-size:80%;
				background-image: url("../images/arrow.svg");
				background-repeat: no-repeat;
				position: relative;
				&:before{
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-size:80%;
					background-position:center 40%;
					background-image: url("../images/arrow--black.svg");
					background-repeat: no-repeat;
					content:"";
					position: absolute;
					transition: opacity .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
					opacity: 0;
					z-index: 1;
				};
				&:after{
					width:100%;
					top:0;
					left:0;
					width:100%;
					height:100%;
					background-color: #fff;
					content:"";
					position: absolute;
					transform:scale(0);
					transition: transform .3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
				};
				&:hover{
					&:before{
						opacity: 1;
					};
					&:after{
						transform:scale(1);
					};
				};
				&--next{
					transform:rotateY(180deg);
				}
			}
		}
		.container{
			overflow:hidden;
		}

	}
	&__strengths{
		padding-top: 178px;
		padding-bottom: 168px;
		&__title{
		    margin-bottom: 42px;
		}
		&__wrap{

			display: flex;
			justify-content: space-between;
		}
		&__slider{
			margin-left: auto;
		    padding-bottom: 30px;
			align-self: flex-start;
		    max-width: 495px;
	    	width: 26.1vw;
	    	will-change: opacity,transform;
	    	transition:transform .5s ease-in-out, opacity .5s ease-in-out;
			
			&__unit{
				&__icon{
					margin-left: 3px;
					margin-bottom: 20px;
				}
				&__title{
					font-weight: 300;
					font-size: 36px;
					line-height: 43px;
					letter-spacing: -1.28571px;
					margin-bottom: 12px;
				}
				&__description{
					font-size: 18px;
					line-height: 30px;
					color: #969696;
					max-width: 450px;
				}
			}
		}

		&__media{
			max-width: 731px;
			width:38.072916666666666666666666666667vw;
			transform: translateX(-62px);
			video{
				width: 100%;
			}
		}


	}
	&__diversity{
		&__title{
			max-width: 520px;
			margin-bottom: 33px;
		}
		&__wrap{
			display: flex;
		}
		&__info{
			max-width:625px;
			width:32.552083333333333333333333333333vw;
		}
		&__text{
			p{
				margin-top:0;
				margin-bottom: 20px;
				&:last-child{
					padding-right: 50px;
					margin-bottom: 0;
				}
			}
		}
		&__media{
			max-width: 658px;
			width:34.270833333333333333333333333333vw;
	        
		    video{
	    	    transform: translate(17%,22.2%);
		    	width: 100%;
		    }
		}
	}
	&--video{
		z-index: 100;
	}
}
@media(min-width: 1920px){
	.team{
		&__slider{
			&__info{
				max-width: 1150px;
				padding-bottom: 74px;
				padding-left: 425px;
				&__position{
				   	margin-bottom: 42px;
				    font-size: 24px;
					line-height: 30px;
				}
				&__name{
					font-size: 48px;
					line-height: 58px;
					letter-spacing: -1.14286px;
				}
				&__description{
					font-size: 24px;
					line-height: 29px;
					letter-spacing: -1.14286px;
					margin-bottom: 38px;
				}
				&__unit{
					padding-left: 107px;
				}
			}
			&__controls{
			    padding-left: 107px;
			}
		}
	}
}
@media(max-width:1600px){
	.page{
		margin-bottom: 248.5px;
		&__contacts{
		    padding-top: 160px;
			padding-bottom: 126px;
		}
	}
	.team{
		&__header{
		   	margin-bottom: 46px;
			padding-top: 135px;
			&__text{
			    max-width: 517px;
			}
		}
		&__title{
		    max-width: 517px;
			margin-bottom: 32.25px;
			font-size:63.75px;
			line-height: 76.5px;
			letter-spacing: -2.2767825px;

		}
		&__video{
			&__button{
				width: 82.5px;
    			height: 82.5px;
			}
		}
		&__subtitle{
		    font-size: 63.75px;
		    line-height: 76.5px;
		    letter-spacing: -2.2767825px;
		}
		&__strengths{
		    padding-bottom: 125px;
			padding-top: 133px;
			&__slider{
				&__unit{
					&__icon{
						margin-left: 2px;
						width: 39px;
						margin-bottom: 15px;
						img{
							width: 100%;
						}
					}
					&__title{
						font-weight: 300;
					    font-size: 27px;
					    line-height: 32.25px;
					    letter-spacing: -0.9642825px;
					    margin-bottom: 9px;
					}
					&__description{
					    font-size: 14px;
    					line-height: 23px;
					    max-width: 300px;
					}
				}
				.slick-next{
					left:calc(100% + 10px);
				}
			}
			&__title{
				margin-bottom: 31.5px;
			}
		}
		&__diversity{
			&__title{
				max-width: 390px;
			}
		}
	}
}
@media(max-width:1023px){
	.page{
		margin-bottom: 372.5px;
		&__contacts{
			padding-top: 125px;
	    	padding-bottom: 110px;
	    }
	}
	.team{
		&__title{
			font-size: 48px;
			line-height: 58px;
			letter-spacing: -3.03571px;
			margin-bottom: 22px;
		}
		&__header{
		    margin-bottom: 39px;
			padding-top: 135px;
			&__text{
				max-width: 600px;
				line-height: 30px;
			}
		}
		&__video{

			&:before{
				padding-top:68.489583333333333333333333333333%;
			}
		}
		&__subtitle{
			font-size: 48px;
			line-height: 58px;
			letter-spacing: -3.03571px;
		}
		&__slider{
			margin:0;
		    padding-top: 97px;
		    .container{
		    	width:100%;
		    	position: relative;
		    	padding-top: 30px;
		    	padding-bottom: 35px;
		    }
		    &__images{
		    	max-width: 952px;
	    	    transform: translateX(-17.3%);
				width:123.95833333333333333333333333333vw;
				&__unit{
					&--highlighted{
						.team__slider__images__unit__wrap{
							transform: translate(-1.25%,0) scale(1.35); 
						}
					}
					&--moved{
						.team__slider__images__unit__wrap{
							transform: translate(31.5%,0);
						}
						&.team__slider__images__unit--highlighted--next{
							.team__slider__images__unit__wrap{
								transform: translate(33.2%,0);
							}
						}
						&.team__slider__images__unit--highlighted--next--next{
						    .team__slider__images__unit__wrap{
						    	transform: translate(32.3%,0);
							}
						}
					}
				}
			}
			&__controls{
				position: absolute;
				bottom:0px;
		        //transform: translateY(126px);
				padding-left: 2.5vw;
				width: 100%;
			    max-width: 302px;
				&__unit{
					width:53px;
					height:24px;
				}
			}
			&__info{
				position: initial;
			    padding-bottom: 47px;
		        padding-left: 298px;
				width:calc(100% - 140px);
				&__position{
					font-size: 18px;
					line-height: 30px;
					margin-bottom: 23px;
				}
				&__name{
					font-size: 30px;
					line-height: 36px;
					letter-spacing: -1.14286px;
					margin-bottom: 24px;
				}
				&__description{
					letter-spacing: 0px;
					max-width: 450px;
					font-size: 16px;
					line-height: 25px;
				}
				&__unit{
				    padding-left: 2.7vw;
				}
			}

		}
		&__strengths{
		    padding-bottom: 134px;
			&__title{
				margin-bottom: 20.5px;
			}
			&__slider{
			    padding-top: 50px;
				width:36.458333333333333333333333333333vw;
				&__unit{
					&__icon{
						width:52px;
						margin-bottom: 33px;
					}
					&__title{
					    margin-bottom: 18px;
						font-size: 30px;
						line-height: 36px;
						letter-spacing: -1.5px;
					}
					&__description{
						font-size: 14px;
						line-height: 20px;
					}
				}
				.slick-next{
					left: calc(100% + 25px);
					bottom:27%;
				}
			}
			&__info{
				margin-left: auto;
			}

			&__media{
				position: absolute;
				
			    transform: translateX(-154px);
				width:56.380208333333333333333333333333vw;
			}
		}
		&__diversity{
			&__wrap{
				display: block;
			}
			&__info{
				max-width: 100%;
				width: 100%;
				margin-bottom: 58px;
			}
			&__title{
			    margin-bottom: 23px;
			    max-width: 270px;
			}
			&__media{
				margin:0 auto;
				width: 100%;
				video{
					transform:none;
				}
			}
			&__text{
				p{
					margin-bottom: 15px;
					&:last-child{
						padding-right: 0;
					}
				}
			}
		}
	}
}
@media(max-width:760px){
	.page__contacts{
		padding-top: 83px;
	    padding-bottom: 114px;
	}
	.team{
		&__subtitle{
			letter-spacing: 0;
			font-size: 24px;
			line-height: 29px;
		}	
		&__title{
			letter-spacing: 0;
			font-size: 24px;
			line-height: 29px;
			    margin-bottom: 19px;
		}
		&__header{
			padding-top: 73px;
			&__text{
				line-height: 25px;
			}
		}
		&__video{
			&:before{
				padding-top:72%;
			};
		}
		&__slider{
			padding-top: 56px;
			.container{
				padding:0;
				display: flex;
				flex-direction: column;

			}
			&__info{
				width:calc(100% - 120px);
				position: relative;
				order:2;
				text-align: center;
				padding-left: 0;
				&__unit{
					padding-left: 0;
				}
				&__position{
					font-size: 16px;
					line-height: 30px;
					margin-bottom: 0px;
				}
				&__name{
					font-size: 24px;
					line-height: 29px;
					letter-spacing: -1.14286px;
				}
				&__description{
				    margin-bottom: 29px;
					max-width: 100%;
					width:100%;
					font-size: 14px;
					line-height: 25px;
					text-align: left;
				}
			}
			&__images{
			    margin-bottom: 27px;
				width:100%;
		        transform:translateX(calc(-100vw + 140px));
				order:1;
				&__unit{
					width:calc(100vw - 140px);
					height:inherit;
					max-height: inherit;
					&:before{
						content:"";
						display: block;
						padding-top:107.75862068965517241379310344828%;
					};
					&__wrap{
						left:15px;
						transform:none!important;
						width:calc(100% - 30px);
						&:before{
							padding-top:123.76237623762376237623762376238%;
						};
					}
					&__inner{
						bottom:0;
						transform:none!important;
					}

				}
			}
			&__controls{
				width: calc(100% + 30px);
   				max-width: calc(100% + 30px);
   				left: - 15px;
				position: absolute;
				bottom:calc(100% - 30px);
				padding-left: 0;
				&__unit{
					&:before,&:after{
						display: none;
					}
				}
			}
		}
		&__strengths{
			padding-top: 43px;
			padding-bottom: 84px;
			&__wrap{
				flex-direction: column;
			}
			&__media{
				order:2;
				position: relative;
				left:50%;
				transform:translateX(-50%);
				width:100vw;
				max-width: 100vw;
			}
			&__info{
			    margin-bottom: 60px;
				width: 100%;
				order:1;
			}
			&__slider{

			    padding-top: 18px;
				width: 100%;
				padding-bottom: 39px;
				&__unit{
					position: relative;
					&__title{
					    padding-left: 71px;
						margin-top:5px;
						font-size: 18px;
						line-height: 22px;
						letter-spacing: 0px;
					    margin-bottom: 29px;
					}
					&__icon{
						margin-left: 1px;
						width:48px;
						position: absolute;
						top:0;
						left:0;
					}
				}
				.slick-next{
					display: none!important;
				}
			}
		}
		&__diversity{
			&__text{
			    padding-bottom: 25px;
			}
			&__media{
				position: relative;
				left:50%;
				transform:translateX(-50%);
				width:100vw;
				max-width: 100vw;
			}
			&__info{
				margin-bottom: 60px;
			}
		}
	}
}