.text{
	&--transparent{
		color:$grey-2;
	}
	&-animated{
		&--eachWord{
			white-space: pre-wrap;
		    display: block;
			position: relative;
			&__word{
				display: inline-flex;
				overflow: hidden;
				&__inner{
					will-change: opacity,transform;
				    transition: transform .2s cubic-bezier(.4,0,1,1),opacity .2s cubic-bezier(.4,0,1,1),-webkit-transform .2s cubic-bezier(.4,0,1,1);
				    display: block;
				    opacity: 0;
				    -webkit-transform: translate3d(0,100%,0);
				    transform: translate3d(0,100%,0);
				    &--visible{

				    	transition: transform .5s cubic-bezier(0,0,.2,1),opacity .5s cubic-bezier(0,0,.2,1),-webkit-transform .5s cubic-bezier(0,0,.2,1);
					    opacity: 1;
					    transform: translateZ(0);
				    }
				}
			}
			&--visible{

			}
		}
		&--shifted{
			will-change: opacity,transform;
			display: inline-block;
		    transition: opacity .5s cubic-bezier(0,0,.2,1),transform .5s cubic-bezier(0,0,.2,1),-webkit-transform .5s cubic-bezier(0,0,.2,1);
			transform:translateY(50%);
			opacity: 0;
			&--visible{
				transform:translateY(0%);
				opacity: 1;
			}
		}
	}
	
}
li{
	&.text-animated--eachWord{
		&:before{
			will-change: opacity;
			transition: opacity .3s ease-in-out;
			opacity: 0;
		}
		&--visible{
			&:before{
				opacity: 1;
			}
		}
	}
}