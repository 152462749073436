.socials{
	display: flex;
	&__unit{
		position: relative;
		width:16px;
		height:16px;
		margin-right: 40px;
		background-position: center;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		&:last-child{
			margin-right: 0;
		}
		&--insta{
			background-image:url("../images/instagram.svg");
		}
		&--twitter{
			width:19px;
			background-image:url("../images/twitter.svg");
		}
		&--fb{
			width:9px;
			background-image:url("../images/facebook.svg");
		}
		&--linkedin{
			background-image:url("../images/linkedin.svg");
		}
		&__circle{
			width:40px;
			height:40px;
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			circle{
				will-change: stroke-dasharray,stroke-dashoffset;
				transition: .8s cubic-bezier(.39,.575,.565,1);
				stroke-dasharray: 150px;
    			stroke-dashoffset: 150px;
			}
		}
		&:hover{
			.socials__unit__circle{
				circle{
					stroke-dashoffset: 0;
				}
				
				&--copy{
					circle{
						transition-delay: .2s;
					}
				}
			}
		}
	}
}
@media(max-width:1600px){
	.socials{
		&__unit{
			width:12px;
			height:12px;
			margin-right: 30px;
			&__circle{
				width:30px;
				height:30px;
			}
			&--twitter{
				width: 14.5px;
			}
			&--fb{
				    width: 7px;
			}
		}
	}
}
@media(max-width:1023px){
	
}
