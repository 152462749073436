$section-transition:cubic-bezier(0.645, 0.045, 0.355, 1);
//$section-transition2:cubic-bezier(.755,.05,.855,.06);

.fullpage-wrapper{
	will-change: transform;
	transition-delay: .5s!important;
}

.section{
	height:100%;
	&__controls{
		display: none;
		padding:10px 0;
		z-index: 3;
		position: fixed;
		bottom:0;
		left:0;
		width:100%;
		
		&__wrap{
			justify-content: space-between;
			display: flex;
		}

		&__button{
			cursor: pointer;
			height:20px;
			line-height: 20px;
			padding:0 12px;
			text-transform:uppercase;
			color:#000;
			font-size: 12px;
			background: rgba(255, 255, 255, 0.86);
			letter-spacing: 2px;


			border:1px solid #000;
			display: flex;
			align-items: center;
			margin-right: 15px;
			transform:translateX(30%);
			opacity: 0;
			visibility: 0;
			transition:all .5s ease-in-out 0s;
			&:last-child{
				margin-right: 0;
			};
			img{
				width: 26px;
			}
			span{
				margin-right: 14px;
			}
			&--prev{
				margin-right: auto;
				img{
					transform: scale(-1, 1);
				}
			}
			&--next{
				margin-left: auto;
			}
			&:nth-child(2){
				transition-delay:.2s;
			}
		}
	}
}
/*
.section{
	position: absolute;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	transition: top .7s $section-transition;
	background-color:$black-bg;
	
	&:not(:first-child){
		top:100%;
	}
	&.active{
		top:0;
		z-index: 2;
	}
	&.active--prev{
		transition:top 0s $section-transition .7s; 
		top:-100%;
	}
	&.active--next{
		transition:top 0s $section-transition .7s; 
		top:100%;
	}
	&.footer{
		background-color: transparent;
	}
	
	&--penultimate{
		&.active--prev{
			top:0;
		}
	}
	&--last{
		z-index: 2;
		&.active--next{
			transition: top .7s $section-transition;
		}
	}
}
*/

@media(max-width:760px){
	.section{
		position: relative;
		&__controls{
			display: block;
			.container{
				width:calc(100% - 20px);
			}
			&--shown{
				.section__controls__button{
					opacity: 1;
					transform:translate(0);
					visibility:visible;
				}
			}
			&--first{
				.section__controls__button--prev{
					opacity: 0;
					transform:translateX(30%);
					visibility:hidden;
				}
			}
			&--last{
				.section__controls__button--next{
					opacity: 0;
					transform:translateX(30%);
					visibility:hidden;
				}
			}
			
		}
	}
}
