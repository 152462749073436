/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 20, 2019 */



@font-face {
    font-family: "Aeonik";
    src: url("Aeonik-Light.ttf") format("truetype");
    
    src: url('aeonik-light-webfont.woff2') format('woff2'),
         url('aeonik-light-webfont.woff') format('woff');
    
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: "Aeonik";
    src: url("Aeonik-Light.ttf") format("truetype");
    
    src: url('aeonik-regular-webfont.woff2') format('woff2'),
         url('aeonik-regular-webfont.woff') format('woff');
    
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: "Aeonik";
    src: url("Aeonik-Light.ttf") format("truetype");
    
    src: url('aeonik-medium-webfont.woff2') format('woff2'),
         url('aeonik-medium-webfont.woff') format('woff');
    
    font-weight: 500;
    font-style: normal;

}