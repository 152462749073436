@keyframes dot{
	0%{
		transform:scale(1);
	}
	5%{
		transform:scale(1.5);
	}
	10%{
		transform:scale(1);
	}
	100%{
		transform:scale(1);
	}

}
@keyframes circle{
	0%{
		width:100%;
		height:100%;
		opacity: .5;
	}
	50%{
		width:1000%;
		height:1000%;
		opacity: 1;
	}
	75%{
		width:1500%;
		height:1500%;
		opacity: .7;
	}
	90%{
		width:2000%;
		height:2000%;
		opacity: 0;
	}
	100%{
		opacity: 0;
		width:100%;
		height:100%;
	}

}
.contacts{
	position: relative;
	height: 100vh;
	min-height:700px;
	display: flex;
	flex-direction:column;
	justify-content: center;
	&:after{
		content:"";
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height:285px;
		pointer-events: none;
		background: linear-gradient(180deg, #1A1C1F 20.35%, rgba(26, 28, 31, 0.712805) 52.15%, rgba(26, 28, 31, 0) 88.6%);
	}
	&__title{
	
		pointer-events: all;
		font-size: 85px;
		line-height: 102px;
		max-width: 500px;

		letter-spacing: -3.03571px;
		font-weight: 300;
		margin-bottom: 145px;

	}
	&__left{
	    width: 50%;
	}
	&__info{
		width:40%;
		&__title{
			pointer-events: all;
			font-size: 46px;
			line-height: 38px;
			margin-bottom: 36px;
			.text-animated--eachWord__word{
				padding-bottom: 6px;
			}
		}
		&__phone{
			margin-top: 20px;
		}
		&__email{
			margin-top:20px;
		}
		&__address{
			font-size: 28px;
    		line-height: 34px;
			
			color:$grey-2;
			margin-bottom: 44px;
			p{
				pointer-events: all;
				margin:0;
			}
			a{
				transition: color .3s ease-in-out;
				&:hover{
					color:#fff;
				};	
			}
		}
		&__button{
			pointer-events: all;
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&__buttons{
			align-items:flex-start;
			flex-wrap:wrap;
			width:100%;
			display: flex;
			justify-content: space-between;
		}
	}
	&__container{
		z-index: 1;
		display: flex;
		flex-direction: column;
		pointer-events: none;
		position: relative;
	}
	&__wrap{
		display: flex;
		justify-content: space-between;
	}
	&__map{
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		&__marker{
			transform:translateY(-53px);
			padding: 27px 47px 23px 47px;
		    letter-spacing: .2px;
			display: inline-block;
			font-family: "Aeonik";
			color:#000;
			font-size: 24px;
			line-height: 29px;
			background-color: #fff;
			position: relative;
			&:before{
				content:"";
				position: absolute;
				top:100%;
				left:calc(50% - 1px);
				width:2px;
				height:53px;
				background-color: #fff;

			}
			&:after{
			    border-radius: 100%;
				content:"";
				background: rgba(26, 25, 25, 0.66);
				width: 166px;
				position: absolute;
				top:calc(100% + 53px);
				height: 8px;
				left:50%;
				transform:translateX(-50%);
			};
		}
		&__dot{
			transform-origin: center center;
			display: inline-block;
			width: 8px;
			height:8px;
			position: relative;
			
			&:before{
				will-change: width,height,opacity;
				animation: circle 3s linear infinite;
				content:"";
				width:100%;
				height:100%;
				position: absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				border-radius: 100%;
				transform-origin:center center;
				border:1px solid rgba(255,255,255,.5);
				background-color: rgba(255,255,255,.1);
			};
			&__center{
				display: inline-block;
				position: absolute;
				transform-origin: center center;
				top:0;
				left:0;
				width: 100%;
				height:100%;
				will-change: transform;
				animation: dot 3s linear infinite;
				border-radius: 100%;
				background-color: #fff;

			}
		}
		div{
			padding:0!important;
			margin:0!important;
			border-radius: 0!important;
			overflow:visible!important;
			outline:none!important;
			box-shadow:none!important;
			background-color: transparent!important;
		}
		a{
			&:not(.contacts__map__dot){
				display: none!important;
			}
		}
		button{
			display: none!important;
		}
		&--mobile{
			display: none;
		}
	}
	&__form{
		
		color:#000;
		z-index: 11;
		background-color: #fff;
		position: fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		will-change: transform;
		transform:translateY(-100%);
		transition: transform .6s cubic-bezier(0.39, 0.575, 0.565, 1);
		.container{
			height:100%;
		}
		
		&__wrap{
			height:100%;
			overflow-y: scroll; /* has to be scroll, not auto */
  			-webkit-overflow-scrolling: touch;
		}
		&__header{
			z-index: 1;
			padding:0 30px;
			align-items: center;
			padding-top: 15px;
			display: flex;
			justify-content: space-between;
			max-width: 1920px;
			margin:0 auto;
			position: absolute;
			top:0;
			left:0;
			right:0;
			width:100%;
		}
		&__logo{
			display: inline-block;
		    width: 66px;
		    img{
		    	display: block;
		    	width:100%;
		    }
		}
		&__close{
			cursor: pointer;
			width:20px;
			height:20px;
			background-size: cover;
			background-image:url("../images/close--black.svg");
		}
		&__footer{
			padding:0 30px;
			pointer-events: none;
			align-items: center;
			padding-bottom: 40px;
			display: flex;
			justify-content: space-between;
			max-width: 1920px;
			margin:0 auto;
			position: absolute;
			z-index: 1;
			bottom:0;
			left:0;
			right:0;
			width:100%;
			&__back{
				pointer-events: all;
				cursor: pointer;
				span{
					&:nth-child(2){
						display: none;
					}
				}
			}
			&__next{
				pointer-events: all;
				cursor: pointer;
				span{
					&:nth-child(2){
						display: none;
					}
				}
			}
			

		}
		&__body{
			position: relative;
			padding-bottom: 60px;
			padding-top:30vh;
		}
		&__progressbar{
			z-index:1;
			position: absolute;
			bottom:0;
			left:0;
			right:0;
			width:100%;
			height:15px;
			&__inner{
				transform-origin: center left;
				will-change: transform;
				transition: transform .6s cubic-bezier(0.39, 0.575, 0.565, 1);
				transform:scaleX(0);
				width:100%;
				height:100%;
				/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#bababa+0,000000+100 */
				background: rgb(186,186,186); /* Old browsers */
				background: -moz-linear-gradient(left,  rgba(186,186,186,1) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left,  rgba(186,186,186,1) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right,  rgba(186,186,186,1) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bababa', endColorstr='#000000',GradientType=1 ); /* IE6-9 */

			}
		}
		&__unit{
			font-size: 20px;
			display: flex;
			will-change: opacity, position;
			margin-bottom: 20px;
			position: absolute;
			opacity: 0;
		    transition: opacity 0s ease-in-out .7s,position 0s ease-in-out .7s;
			label{
				cursor: pointer;
				padding:0 5px;
				position: relative;
				input{
					width:1px;
					height:1px;
					position:absolute;
					opacity: 0;
					top:0;
					left:0;
					&:checked + span{
						opacity: 1;
					}
				}
				span{
					opacity:.5;
				}
			}
			&__body{
				max-width: 70%;
				will-change: opacity,transform;
				display: flex;
				align-items: center;
				padding: 20px 35px;
    			border-radius: 25px;
				background-color:#e5e5ea;
				opacity: 0;
				transform:scale(.7);
				transform-origin: left bottom;
				transition:opacity .5s cubic-bezier(0.55, 0.085, 0.68, 0.53), transform .5s ease-in-out;
				p{
					margin:0;
				}
				input[type="email"]{
					min-width: 300px;
				}
				&--close{
					background-color: #000;
					color:#fff;
					cursor: pointer;
					&:hover{
						p{
							&:before{
								transform:translateY(-50%)rotate(180deg);
							};
						}
					};
					p{
						position: relative;
						padding-right: 40px;
						&:before{
							transition:transform .5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
							width:20px;
							height:20px;
							background-size: cover;
							background-image:url("../images/close.svg");
							content:"";
							position: absolute;
							right:0;
							top:50%;
							transform:translateY(-50%);
						}
					}
					
				}
			}
			&--right{

				justify-content: flex-end;
				.contacts{
					&__form{
						&__unit{

							&__body{
								transform-origin: right bottom;
								
								background-color:#007bff;
								color:#fff;

								input,textarea{

									font-family: "Aeonik";
									padding:0px;
									border:none;
									outline:none;
									background-color: transparent;
									color:#fff;
									&::-webkit-input-placeholder { 
									  color: rgba(255,255,255,.7);
									}
									&::-moz-placeholder { 
									  color: rgba(255,255,255,.7);
									}
									&:-ms-input-placeholder { 
									  color: rgba(255,255,255,.7);
									}
									&:-moz-placeholder { 
									  color: rgba(255,255,255,.7);
									}
								}
								#start-date{
									    max-width: 150px;
								}
								#finish-date{
									    max-width: 150px;
								}
								textarea{
									min-height: 150px;
									max-width: 600px;
									width:40vw;
									resize: vertical;
								}
							}

						}
					}
				}
			}
			&--sourceSwitch{
				margin-bottom: 100px;
			}
		}
		&--shown{
			transform:translateY(-0%);
		}
		&__segment{
			&--shown{
				.contacts{
					&__form{
						&__unit{
							transition: opacity 0s ease-in-out 0,position 0s ease-in-out 0;
							position: static;
							opacity: 1;
							&:nth-child(1){
								.contacts__form__unit__body{
									transition-delay: .7s;
								}
							}
							&:nth-child(2){
								.contacts__form__unit__body{
									transition-delay: 1.4s;
								}
							}
							&:nth-child(3){
								.contacts__form__unit__body{
									transition-delay: 2.1s;
								}
							}
							&__body{
								opacity: 1;
								transform:scale(1);
							}
						}
					}
				}
			}
		}
	}
}
.gm-style .gm-style-iw-t::after{
	display: none;
}
@media(max-width:1600px){
	.contacts{
		&__title{
			font-size: 64px;
		    line-height: 75px;
		    letter-spacing: -2.23571px;
		    margin-bottom: 109px;
	        max-width: 380px;
		}
		&__info{
			&__title{
				font-size: 34px;
			    line-height: 29px;
			    margin-bottom: 27px;
			}
			&__address{
				font-size: 25px;
			    line-height: 29px;
			    margin-bottom: 33px;
			}
		}
		&__map{
			
			&__marker{
				transform:translateY(-40px);
				padding: 20px 35px 17px 35px;
			    letter-spacing: .15px;
				display: inline-block;
				font-family: "Aeonik";
				color:#000;
				font-size: 18px;
				line-height: 22px;
				background-color: #fff;
				position: relative;
				&:before{
					content:"";
					position: absolute;
					top:100%;
					left:calc(50% - 1px);
					width:2px;
					height:40px;
					background-color: #fff;

				}
				&:after{
				    border-radius: 100%;
					content:"";
					background: rgba(26, 25, 25, 0.66);
					width: 124px;
					position: absolute;
					top:calc(100% + 50px);
					height: 6px;
					left:50%;
					transform:translateX(-50%);
				};
			}
		}
		&__form{
			&__logo{
			    width: 50px;
			}
			&__unit{
				&--right{
					.contacts__form__unit__body{
						#start-date{
							max-width: 105px;
						}
						#end-date{
							max-width: 105px;
						}
					}
				}
			}
		}
	}
}
@media(max-width:1023px){
	.contacts{
		
		&__title{
			max-width: 260px;
			margin-bottom: 38px;
			font-size: 45px;
			line-height: 55px;
			text-align: left;
			letter-spacing: -3.03571px;
		    
		}
		&__info{
			&__address{
				font-size: 18px;
			    line-height: 30px;
			    margin-bottom: 23px;
			}
			&__title{
			    margin-bottom: 30px;
				font-size: 24px;
				line-height: 38px;
			}
		}
		&__form{
			&__unit{
				label{
					padding:0;
					display: block;
				}
				&--sourceSwitch{
					margin-bottom: 75px;
				}
				&--right{
					.contacts__form__unit__body{
						
					}
				}
				&__body{
					max-width: 80%;
				}
			}
			
		}
	}
}
@media(max-width:760px){
	.contacts{
		position: relative;
		min-height:450px;
		&__title{
			width:100%;
			letter-spacing: 0;
			font-size: 24px;
			line-height: 29px;
		    margin-bottom: 25px;
		}
		&__info{
			width: 100%;
			&__address{
				font-size: 14px;
			    line-height: 20px;
			    margin-bottom: 27px;
			    p{
			    	margin-bottom: 5px;
			    	&:last-child{
			    		margin-bottom: 0;
			    	};
			    }
			}
			&__title{
				margin-bottom: 5px;
				font-size: 16px;
				line-height: 19px;
			}
			
		}
		&__left{
			width: 	100%;
			margin-bottom: 40px;
		}
		&__wrap{
			flex-direction: column;
		}
		&__map{

			display: none;
			&__marker{
				text-align: center;
				font-size: 14px;
				line-height: 17px;
			    padding: 19px 20px 18px 19px;
			}
			&--mobile{
				overflow:hidden;
				display: block;
				position: absolute;
				bottom:0;
				right:0;
				height:100%;
				min-width: 450px;
				min-height: 882px;
				width:100%;
				background-image: url(../images/contacts-map-mobile.jpg);
				background-size: cover;
				background-position: bottom right;
			}
			&__dot{
				&--mobile{
					transform:translate(50%,50%);
					position: absolute;
				    bottom: 6.7%;
    				right: 13.5%;
				}
			}
		}
		&__form{
			&__header{
				padding-left: 10px;
				padding-right: 10px;
			}
			&__close{
				width:15px;
				height:15px;
			}
			&__body{
				padding-bottom: 100px;
			}
			&__logo{
			    width: 41px;
			}
			&__footer{
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 30px;
				&__next{
					span{
						&:nth-child(1){
							display: none;
						}
						&:nth-child(2){
							text-transform:uppercase;
							display: inline;
						}
					}
				}
				&__back{
					span{
						&:nth-child(1){
							display: none;
						}
						&:nth-child(2){
							text-transform:uppercase;
							display: inline;
						}
					}
				}
			}
			&__unit{
				font-size: 16px;
				
				&__body{
					max-width: 90%;
					input[type="email"]{
						min-width: 210px;
					}
					
				}
				&--sourceSwitch{
					margin-bottom: 50px;
				}
				&--right{
					.contacts{
						&__form{
							&__unit{
								&__body{
									#start-date{
									    max-width: 120px;
									}
									#end-date{
									    max-width: 120px;
									}
									textarea{
									    width: 50vw;
										min-height: 120px;
									}
								}
							}
						}
					}
				}
			}
		}

	}
}