@keyframes glitter {
  0% {
    -webkit-transform: scale(0.5) rotate(0deg);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(1) rotate(360deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0.5) rotate(720deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.5) rotate(0deg);
    opacity: 0;
  }
}

.sparkles{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	pointer-events: none;
	z-index: 1;
	&__unit{
		width:1px;
		height:2px;
		background-color:#fff;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		overflow: hidden;
		z-index: 2;
		color: tansparent;
		opacity: 0.0;
		will-change:opacity,transform;
		animation: glitter 8s linear 0s infinite normal;
		&--medium{
			width:2px;
			height:2px;

		}
		&--big{
			width:3px;
			height:3px;
		}
	}
}
