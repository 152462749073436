@keyframes loading-anim {
	
  	0% {
        transform:translate(-50%,-50%) rotate(0deg);
  	}
  	
  	100% {
   	    transform:translate(-50%,-50%) rotate(360deg);
  	}
  	
}
.preloader{
	position: fixed;
	z-index: 100;
	top:0;
	height:100%;
	width: 100%;
	left:0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $black-bg;
	will-change: opacity,visibility;
	transition: .5s cubic-bezier(.755, .05, .855, .06);
	
	&__logo{
		position: relative;
		&:before{
			position: absolute;
			top:50%;
			left:50%;
			transform:translate(-50%,-50%);
			content: '';
			width: 170px;
			height: 170px;
			border: 1px solid #111517;
    		border-left-color: rgb(17, 21, 23);
			border-left-color: white;
			will-change: transform;
			animation: 1.2s loading-anim cubic-bezier(.77, 0, .175, 1) infinite both;
			border-radius: 50%;
			display: block;
		};
		img{
			width: 100%;
			display: block;
		}
	}
	&--hidden{
		visibility: hidden;
		opacity: 0;
	}
	&--nologo{
		.preloader__logo{
			img{
				display: none;
			}
		}
	}
}
@media(max-width:1600px){
	.preloader{
		&__logo{
			width: 75px;
			&:before{
				width:128px;
				height:128px;
			}
		}
	}
}