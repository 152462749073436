.photo__grid{
	width:65.052083333333333333333333333333%;
	max-width: 1249px;
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom:-7.5%;
	right:0;
	padding-right: 0.880704563650920736589271417134%;
	&__column{
		display: flex;
		flex-direction: column-reverse;
		width:15.13210568454763811048839071257%;
		&__unit{
			will-change: opacity,transition;
			transform:translateY(-110vh);
			opacity: 0;
			transition:all .5s cubic-bezier(0.23, 1, 0.32, 1);
			margin:5% 0;
			background-size:cover;
			transition-delay: 0s;
			&:before{
				content:"";
				display: block;
				padding-top:66.536964980544747081712062256809%;
			};
			&--big{
				&:before{
					padding-top:75.875486381322957198443579766537%;
				}
			}
			
			&--shown{
				opacity:1;
				transform:translateY(0vh);
				&:nth-child(1){
					transition-delay: 0s;
				}
				&:nth-child(2){
					transition-delay: .1s;
				}
				&:nth-child(3){
					transition-delay: .2s;
				}
				&:nth-child(4){
					transition-delay: .3s;
				}
				&:nth-child(5){
					transition-delay: .4s;
				}
				&:nth-child(6){
					transition-delay: .5s;
				}
				&:nth-child(7){
					transition-delay: .6s;
				}
				&:nth-child(8){
					transition-delay: .7s;
				}
				&:nth-child(9){
					transition-delay: .8s;
				}
				&:nth-child(10){
					transition-delay: .9s;
				}
				&:nth-child(11){
					transition-delay: 1s;
				}
				&:nth-child(12){
					transition-delay: 1.1s;
				}
				&:nth-child(13){
					transition-delay: 1.2s;
				}
				&:nth-child(14){
					transition-delay: 1.3s;
				}
				&:nth-child(15){
					transition-delay: 1.4s;
				}
				&:nth-child(16){
					transition-delay: 1.5s;
				}
				&:nth-child(17){
					transition-delay: 1.6s;
				}
				&:nth-child(18){
					transition-delay: 1.7s;
				}
			}
		}
		&:nth-child(2n){
			padding-bottom: 5%;
		}
	}
}
@media(max-width:1023px){
	.photo__grid{
		max-width: 720px;
	    right: -3%;
	    bottom:0;
	    transform:translateY(7.5%);
		width: 79.052083%;
	}
}
@media(max-width:760px){
	.photo__grid{
	    max-width: 720px;
	    right: -11%;
	    bottom: 0;
	    -webkit-transform: translateY(7.5%);
	    transform: translateY(9%);
	    width: 99.052083%;
	}
}