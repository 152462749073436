@keyframes input-animated{
	0%{
		transform:scaleX(0);
	}
	49%{
		transform-origin: left center;	
	}
	50%{
		transform:scaleX(1);
		transform-origin: right center;
	}
	100%{
		transform:scaleX(0);
		transform-origin: right center;
	}
}
.form{
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: #fff;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: #fff;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: #fff;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: #fff;
	}
	&__unit{
		width:100%;
		position: relative;
		margin-bottom: 30px;
		&:last-child{
			margin-bottom: 0;
		};
		input:not([type="submit"]){
			width:100%;
			outline:none;
			box-shadow: none;
			height:60px;
			background-color: transparent;
			color:#fff;
			font-size: 16px;
			padding:0 20px;
			border:1px solid #fff;
		}
		textarea{
			display: block;
			padding:20px;
			font-size: 16px;
			line-height: 20px;
			background-color: transparent;
			border:1px solid #fff;
			width:100%;
			height:160px;
			resize: none;
			color:#fff;
		}
		&__content{
			opacity: 0;
		}
		&--centered{
			text-align: center;
		}
		&:after{

			z-index: 1;
			position: absolute;
			content:"";
			top:0;
			left:0;
			width:100%;
			height:100%;
			transform-origin: center left;
			background-color: #fff;
			transform:scaleX(0);
		};
		&--shown{
			.form{
				&__unit{
					&__content{
						opacity: 1;
						transition: opacity .1s linear .4s;
					}
				}
			}
			&:after{
				animation: input-animated .8s cubic-bezier(0.445, 0.05, 0.55, 0.95) 1;
				animation-fill-mode: forwards;
			};
		}
		&--submit{
			&:after{
				display: none;
			};
			.form{
				&__unit{
					&__content{
						opacity: 1;
					}
				}
			}
		}
	}

	&__submit{

		//border:1px solid #fff;
		position: relative;
		width:100px;
		//height:60px;
		//line-height: 60px;
		//text-align: center;
		//text-transform: uppercase;
		margin:0 auto;
		span{
			position: relative;
		}
		input{
			position: absolute;

			width: 1px;
			height:1px;
			opacity: 0;
			pointer-events: none;
		}
	}
}



@media(max-width:1600px){
	.form{
		&__unit{
			margin-bottom: 23px;
			input:not([type="submit"]){
				height: 45px;
			    font-size: 12px;
			    padding: 0 15px;
			}
			textarea{
				padding: 15px;
			    font-size: 12px;
			    line-height: 15px;
			    height: 120px;
			}
		}
	}
}
@media(max-width:1023px){
	.form{
		&__unit{
			margin-bottom: 30px;
			input:not([type="submit"]){
				height: 60px;
			    font-size: 16px;
			    padding: 0 24px;
			}
			textarea{
				height:160px;
				padding:23px 24px;
				font-size: 16px;
			}
		}
	}
}