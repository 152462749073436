@function max($numbers...) {
  @return m#{a}x(#{$numbers}); }

@function min($numbers...) {
  @return m#{i}n(#{$numbers}); }

@import "./normalize";
@import "./vars.sass";
@import "./mixins";
@import "./fonts";
@import "./sprite_generated";
@import "../../libs/slick-carousel/slick/slick.scss";
@import "../../libs/fullpage.js/dist/fullpage";
@import "../../libs/@fancyapps/fancybox/dist/jquery.fancybox";

* {
  box-sizing: border-box;
  outline: none; }

body {
    display: flex;
    flex-direction: column;
    background: $black-bg;
    font-family: "Aeonik";
    width: 100%;
    font-size: 18px;
    line-height: 30px;
    height: 100%;
    min-height: 100vh;
    overflow: hidden!important;
    color: #fff;
    @media(max-width:1600px) {
        font-size: 14px;
        line-height: 23px; }
    @media(max-width:1023px) {
        font-size: 16px;
        line-height: 25px; }
    @media(max-width:760px) {
        font-size: 14px;
        line-height: 25px; }
    &.body--loaded {
        overflow: initial!important;
        .main {
            opacity: 1; } }
    &.body--team {
        .main {
            opacity: 1; } } }


.wrapper {
    width: 100%;
    max-width: 850px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 40px; }
